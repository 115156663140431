import styled, {css} from "styled-components";
import {OpenDropdownProps, StyledInputProps} from "../../store/types";

export const Container = styled.div`
  position: absolute;
  top: 195px;
  left: 340px;
  right: 10px;
  bottom: 10px;
  border-bottom: 1px solid rgb(9, 32, 39);
  box-shadow: 0 8px 10px -6px rgba(6, 7, 8, 0.06), 0px 25px 50px -12px rgba(6, 7, 8, 0.16);
`;
export const TopBlock = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;
export const BottomBlock = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;

export const Text = styled.p`
  position: relative;
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0;
  text-align: left;
    white-space: nowrap;
    @media (max-width: 1440px) {
        font-size: 15px;
    }
    @media (max-width: 1024px) {
        font-size: 10px;
    }
`;
export const CustomScrollBlock = styled.div`
  position: absolute;
  max-width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid rgb(54, 58, 61);
  border-top: none;
  border-radius: 0 0 8px 8px;
    z-index: 1000;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(130, 219, 247);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(54, 58, 61);
    border-radius: 8px;
  }
`;

export const DropdownItem = styled.div`
  padding: 8px 20px;
  cursor: pointer;
  background: rgb(26, 29, 33);
  color: rgb(155, 156, 158);
  border-radius: 8px;
  &:hover {
    background: linear-gradient(90deg, rgb(130, 219, 247), rgb(182, 240, 156)) 1px;
    color: black;
  }
`;
export const InputBlock = styled.div<OpenDropdownProps>`
  position: relative;
  width: 25%;
  height: auto;
  padding: 20px;
  border-radius: 8px;
  ${(props) =>
          props.isActive &&
          css`
            border-color: rgb(130, 219, 247);
            box-shadow: 0 0 0 1px rgb(182, 240, 156), 0 0 5px 4px rgba(132, 220, 245, 0.24);
          `}
`;

export const InputContainer = styled.div<StyledInputProps>`
   position: relative;
`;
export const DateContainer = styled.div<StyledInputProps>`
    position: relative;
    padding-left: 20px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    text-align: left;
    min-height: 48px;
    border-radius: 8px;
    background: rgb(26, 29, 33);
    color: rgb(155, 156, 158);
    transition: border-color 0.3s ease;
    outline: none;
    box-sizing: border-box;
    resize: none;
    border: ${(props) => {
        if (props.$error) {
            return '1px solid rgb(208, 48, 47)';
        }
        if (!props.$warning && props.$hasText) {
            return '1px solid rgb(226, 111, 32)';
        } else {
            return '1px solid rgb(54, 58, 61)';
        }
    }
    };
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    &::-webkit-calendar-picker-indicator {
        display: none;
    }
    &:not(:placeholder-shown) {
        border-color: ${(props) => (props.$hasText && props.$warning ? 'green' : '1px solid rgb(226, 111, 32)')};
    }


    &:focus {
        border-color: rgb(130, 219, 247);
        box-shadow: 0 0 0 1px rgb(182, 240, 156), 0 0 5px 4px rgba(132, 220, 245, 0.24);
        display: block;
    }
    &:focus::before {
        border-color: rgba(132, 220, 245, 0.24);
    }

    @media (max-width: 768px) {
        padding-left: 45px;
    }
`;
export const Input = styled.input<StyledInputProps>`
  position: relative;
  padding-left: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  text-align: left;
  min-height: 48px;
  border-radius: 8px;
  background: rgb(26, 29, 33);
  color: rgb(155, 156, 158);
  transition: border-color 0.3s ease;
  outline: none;
  box-sizing: border-box;
  resize: none;
  border: ${(props) => {
    if (props.$error) {
      return '1px solid rgb(208, 48, 47)';
    }
    if (!props.$warning && props.$hasText) {
      return '1px solid rgb(226, 111, 32)';
    } else {
      return '1px solid rgb(54, 58, 61)';
    }
  }
  };
  overflow: hidden;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
  &:not(:placeholder-shown) {
    border-color: ${(props) => (props.$hasText && props.$warning ? 'green' : '1px solid rgb(226, 111, 32)')};
  }

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border: 1px solid transparent;
    border-radius: 8px;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
  }
  &:focus {
    border-color: rgb(130, 219, 247);
    box-shadow: 0 0 0 1px rgb(182, 240, 156), 0 0 5px 4px rgba(132, 220, 245, 0.24);
      display: block;
  }
  &:focus::before {
    border-color: rgba(132, 220, 245, 0.24);
  }

  @media (max-width: 768px) {
    padding-left: 45px;
  }
`;
export const BigInput = styled.input<StyledInputProps>`
  position: relative;
  padding-left: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  text-align: left;
  min-height: 48px;
  border-radius: 8px;
  background: rgb(26, 29, 33);
  color: rgb(155, 156, 158);
  transition: border-color 0.3s ease;
  outline: none;
  box-sizing: border-box;
  resize: none;
  border: ${(props) => {
    if (props.$error) {
        return '1px solid rgb(208, 48, 47)';
    }
    if (!props.$warning && props.$hasText) {
        return '1px solid rgb(226, 111, 32)';
    } else {
        return '1px solid rgb(54, 58, 61)';
    }
}
};
  overflow: hidden;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
  &:not(:placeholder-shown) {
    border-color: ${(props) => (props.$hasText && props.$warning ? 'green' : '1px solid rgb(226, 111, 32)')};
  }

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border: 1px solid transparent;
    border-radius: 8px;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
  }
  &:focus {
    border-color: rgb(130, 219, 247);
    box-shadow: 0 0 0 1px rgb(182, 240, 156), 0 0 5px 4px rgba(132, 220, 245, 0.24);
  }
  &:focus::before {
    border-color: rgba(132, 220, 245, 0.24);
  }

  @media (max-width: 768px) {
    padding-left: 45px;
  }
`;

export const Button = styled.button`
  position: relative;
  margin-top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 48px;
  width: 10%;
  background-color: rgb(39, 133, 168);
  color: rgb(12, 17, 50);
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;

  &:hover {
    background-color: rgb(32, 109, 138);
    color: rgb(12, 17, 50);
  }

  &:active {
    background-color: rgb(24, 82, 103);
    color: rgb(12, 17, 50);
  }
  @media (max-width: 768px) {
    width: 80%;
    margin: 0 auto;
  }
    @media (max-width: 1440px) {
        width: 30%;
    }
`;
