import React, { useRef, useState } from "react";
import {
  Button, DialogAboutTask,
  DividerContainer,
  DividerLine,
  DividerText,
  ErrorBlock,
  ForgotPasswordText, Highlight,
  InputContainer, List, ListItem, Overlay,
  SmallText,
  SocialLoginButton, SubtitleError,
  TextGradient
} from "./styles";
import { Container, ContinueContainer, Text } from "../../style/GlobalStyle";
import GoogleIcon from "../../assets/GoogleIcon";
import DangerComponent, { DangerMessageType } from "../../style/DangerComponent";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { useAppDispatch } from "../../store/hooks";
import { IUserLogin } from "../../store/types";
import {
  loginFacebookUserAsync,
  loginGoogleUserAsync,
  loginUserAsync,
  sendResetPasswordMail
} from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { CrossIconWrapper } from "../TopBlock/styles";
import CircleIcon from "../../assets/CircleIcon";
import FacebookIcon from "../../assets/FacebookIcon";
import NFC from "../nfc/NFC";

const LoginForm: React.FC = () => {
  const [showBlock, setShowBlock] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const error = useSelector((state: RootState) => state.app.error);
  const loading = useSelector((state: RootState) => state.app.loading);
  const [showNotification, setShowNotification] = React.useState(false);
  const formikRef = useRef<FormikProps<IUserLogin>>(null);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Incorrect email entered*")
      .required("Login is obligatory field"),
    password: Yup.string()
      .required("Incorrect password entered*")
      .min(6, "Password is too short - should be 6 chars minimum.")
  });

  const userCredentials: IUserLogin = {
    email: "",
    password: ""
  };

  const closeNotificationHandler = () => {
    setShowNotification(false);
  };
  const handleSendResetpassword = () => {
    if (formikRef.current?.values.email != null) {
      dispatch(sendResetPasswordMail(formikRef.current?.values.email))
        .then((result: any) => {
          if (result.meta.requestStatus === "fulfilled") {
            setShowNotification(true);
            return;
          } else {
            switch (result.payload.code) {
            case "auth/missing-email":
              setShowNotification(true);
              break;
            default:
              console.log("An unknown error occurred:", result.payload.code);
            }
          }
        });
    }
  };
  const loginFunc = (values: IUserLogin) => {
    dispatch(loginUserAsync(values))
      .then((result: any) => {
        if (result.meta.requestStatus === "fulfilled") {
          navigate(routes.HOME);
          return;
        } else {
          switch (result.payload.code) {
          case "auth/invalid-email":
            setShowNotification(true);
            break;
          case "auth/user-disabled":
            setShowNotification(true);
            break;
          case "auth/user-not-found":
            setShowNotification(true);
            break;
          case "auth/wrong-password":
            setShowNotification(true);
            break;
          case "auth/invalid-credential":
            setShowNotification(true);
            break;
          case "auth/multi-factor-auth-required":
            console.log("fock");
            break;
          default:
            console.log("An unknown error occurred:", result.payload.code);
          }
        }
      });
  };
  const googleLogin = () => {
    dispatch(loginGoogleUserAsync(formikRef.current!.values))
      .then((result: any) => {
        if (result.meta.requestStatus === "fulfilled") {
          navigate(routes.HOME);
          return;
        } else {
          switch (result.payload.code) {
          case "auth/invalid-email":
            setShowNotification(true);
            break;
          case "auth/user-disabled":
            setShowNotification(true);
            break;
          case "auth/user-not-found":
            setShowNotification(true);
            break;
          case "auth/wrong-password":
            setShowNotification(true);
            break;
          case "auth/invalid-credential":
            setShowNotification(true);
            break;
          default:
            console.log("An unknown error occurred:", result.payload.code);
          }
        }
      });
  };
  const facebookLogin = () => {
    dispatch(loginFacebookUserAsync(formikRef.current!.values))
      .then((result: any) => {
        if (result.meta.requestStatus === "fulfilled") {
          navigate(routes.HOME);
          return;
        } else {
          switch (result.payload.code) {
          case "auth/invalid-email":
            setShowNotification(true);
            break;
          case "auth/user-disabled":
            setShowNotification(true);
            break;
          case "auth/user-not-found":
            setShowNotification(true);
            break;
          case "auth/wrong-password":
            setShowNotification(true);
            break;
          case "auth/invalid-credential":
            setShowNotification(true);
            break;
          case "auth/multi-factor-auth-required":
            setShowNotification(true);
            break;
          default:
            console.log("An unknown error occurred:", result.payload.code);
          }
        }
      });
  };


  return (
    <Container>
      {showNotification ?
        <DangerComponent
          show={showNotification}
          close={closeNotificationHandler}
          error={error}
          color="green"
          icon={<span>⚠️</span>}
          type={DangerMessageType.Error}
        /> : null}
      <Text>Let's create a<TextGradient onClick={() => setShowBlock(true)}>task!</TextGradient></Text>
      <Formik initialValues={userCredentials} validationSchema={validationSchema} onSubmit={loginFunc}
              innerRef={formikRef}>
        {({ errors, touched }) => (
          <Form style={{ width: "100%" }}>
            <ErrorBlock>
              <ErrorMessage name="email"
                            component="p"
                            className="error-message" />
              <ErrorMessage name="password"
                            component="p"
                            className="error-message" />
              {error && <SubtitleError>{error.code}</SubtitleError>}
            </ErrorBlock>
            <InputContainer $error={!!errors.email && touched.email}>
              <Field type="email" name="email" placeholder="Email" />
            </InputContainer>
            <InputContainer $error={!!errors.password && touched.password}>
              <Field type="password" name="password" placeholder="Password" />
            </InputContainer>
            <Button type="submit" disabled={loading}>
              {loading ? "Logging in..." : "Login"}
            </Button>
          </Form>

        )}
      </Formik>
      <DividerContainer>
        <DividerLine />
        <DividerText>or continue with</DividerText>
        <DividerLine />
      </DividerContainer>
      <ContinueContainer>
        <SocialLoginButton onClick={googleLogin}><GoogleIcon />Google Account</SocialLoginButton>
        <SocialLoginButton onClick={facebookLogin}><FacebookIcon />Facebook Account</SocialLoginButton>
      </ContinueContainer>
      <ForgotPasswordText onClick={handleSendResetpassword}>Forgot
        password?</ForgotPasswordText>
      <SmallText> Don't have an account?
        <TextGradient onClick={() => navigate(routes.REGISTER)}>Sign
          up</TextGradient>
      </SmallText>
      {showBlock ? <Overlay>
        <DialogAboutTask>
          <CrossIconWrapper>

            <CircleIcon onClick={() => setShowBlock(false)} />

          </CrossIconWrapper>
          <Highlight>Task Definition in CodeMinds</Highlight>
          <p>A <Highlight>Task</Highlight> in CodeMinds is a specific programming challenge or project posted by
            companies or individuals seeking expert solutions. Tasks can range from small bug fixes and feature
            implementations to larger, more complex software development projects.</p>

          <Highlight>Key Features of a Task:</Highlight>
          <List>
            <ListItem>
              <Highlight>Detailed Description:</Highlight> Each task includes a comprehensive description outlining the
              requirements, goals, and expected deliverables.
            </ListItem>
            <ListItem>
              <Highlight>Skills Required:</Highlight> The task specifies the necessary skills and technologies needed to
              complete the task successfully.
            </ListItem>
            <ListItem>
              <Highlight>Deadline:</Highlight> A clear deadline is provided to ensure timely completion of the task.
            </ListItem>
            <ListItem>
              <Highlight>Budget:</Highlight> Information about the budget or compensation for completing the task.
            </ListItem>
            <ListItem>
              <Highlight>Communication:</Highlight> Tools for direct communication between the task creator and the
              developers working on the task.
            </ListItem>
            <ListItem>
              <Highlight>Submission and Review:</Highlight> A system for submitting the completed work and receiving
              feedback from the task creator.
            </ListItem>
          </List>

          <p>Tasks are the core of CodeMinds, facilitating collaboration between project creators and skilled developers
            from around the world, helping to bring innovative solutions to life.</p>


        </DialogAboutTask>
      </Overlay> : null}

    </Container>
  );
};

export default LoginForm;
