import React from "react";
import {
    BalanceBlock, BalanceItem, Button, ButtonFree, ButtonsContainer, CentralText, CentralTextWrapper,
    Container, InnerContainer, PayBlock, PayContainer, PayWrapper, SecondItem,
    Title, YourSecondItem,
} from "./styles";
import {IData, INewUser} from "../../store/types";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {format, parseISO} from "date-fns";
import {addBalanceAsync} from "../../store/slices/userSlice";


const BillingComponent: React.FC = () => {
    const user: INewUser = useAppSelector((state) => state.app.user!);
    const data: IData = useAppSelector(state => state.app.data!)
    const parsedEndDate = parseISO(data?.endDate || "2000-01-01 13:48:00.000000");
    const formattedEndDate = format(parsedEndDate, 'yyyy-MM-dd');
    const dispatch = useAppDispatch();
    const addBalanceButton = () => {
        dispatch(addBalanceAsync({userId: user.uid!}))
    };
    return (
        <Container>
            <BalanceBlock>
                <InnerContainer>
                    <BalanceItem>Balance:</BalanceItem>
                    <YourSecondItem>{data?.balance || 0}$</YourSecondItem>
                </InnerContainer>
                <InnerContainer>
                    <BalanceItem>Status:</BalanceItem>
                    <YourSecondItem>{data?.status || "Free"}</YourSecondItem>
                </InnerContainer>
                <InnerContainer>
                    <BalanceItem>End date:</BalanceItem>
                    <SecondItem>{formattedEndDate}</SecondItem>
                </InnerContainer>
                <Button onClick={addBalanceButton}>Add balance</Button>

            </BalanceBlock>
            <CentralTextWrapper>
                <CentralText>Our tasks:</CentralText>
                <CentralText>Creating an active and mutually supportive community.
                    Facilitating the process of searching for projects and hiring specialists.
                    Assistance in developing the skills and experience of programmers.</CentralText>
                <CentralText>We strive to create a dynamic and progressive web application designed for programmers and
                    customers to facilitate interaction and joint development of projects. CodeMinds will be a hub for
                    networking, learning, knowledge sharing and challenge creation, providing unique collaboration
                    opportunities for members.</CentralText>
            </CentralTextWrapper>
            <PayWrapper>
                <PayBlock>
                    <Title>Newbie</Title>
                    <PayContainer>
                        <BalanceItem>Ai assistant:</BalanceItem>
                        <YourSecondItem>1</YourSecondItem>
                    </PayContainer>
                    <PayContainer>
                        <BalanceItem>Education:</BalanceItem>
                        <YourSecondItem>Completing tasks</YourSecondItem>
                    </PayContainer>
                    <PayContainer>
                        <BalanceItem>Tasks:</BalanceItem>
                        <YourSecondItem>Connect,Read,Write</YourSecondItem>
                    </PayContainer>
                    <PayContainer>
                        <BalanceItem>Price:</BalanceItem>
                        <YourSecondItem>1$/month</YourSecondItem>
                    </PayContainer>

                    <ButtonsContainer>
                        <ButtonFree>Free Trial</ButtonFree>
                        <Button>Subscribe</Button>
                    </ButtonsContainer>
                </PayBlock>
                <PayBlock>
                    <Title>Developer</Title>
                    <PayContainer>
                        <BalanceItem>Ai assistant:</BalanceItem>
                        <YourSecondItem>5</YourSecondItem>
                    </PayContainer>
                    <PayContainer>
                        <BalanceItem>Education:</BalanceItem>
                        <YourSecondItem>Creating, Completing</YourSecondItem>
                    </PayContainer>
                    <PayContainer>
                        <BalanceItem>Tasks:</BalanceItem>
                        <YourSecondItem>Create 10 tasks/per day.
                            Connect,Read,Write
                            Create private channels</YourSecondItem>
                    </PayContainer>
                    <PayContainer>
                        <BalanceItem>Price:</BalanceItem>
                        <YourSecondItem>10$/month</YourSecondItem>
                    </PayContainer>
                    <ButtonsContainer>
                        <ButtonFree>Free Trial</ButtonFree>
                        <Button>Subscribe</Button>
                    </ButtonsContainer>
                </PayBlock>
                <PayBlock>
                    <Title>Developer plus</Title>
                    <PayContainer>
                        <BalanceItem>Ai assistant:</BalanceItem>
                        <YourSecondItem>10</YourSecondItem>
                    </PayContainer>
                    <PayContainer>
                        <BalanceItem>Education:</BalanceItem>
                        <YourSecondItem>Creating, Completing</YourSecondItem>
                    </PayContainer>
                    <PayContainer>
                        <BalanceItem>Tasks:</BalanceItem>
                        <YourSecondItem>Create unlimited tasks.
                            Full access.</YourSecondItem>
                    </PayContainer>
                    <PayContainer>
                        <BalanceItem>Price:</BalanceItem>
                        <YourSecondItem>15$/month</YourSecondItem>
                    </PayContainer>
                    <ButtonsContainer>
                        <ButtonFree>Free Trial</ButtonFree>
                        <Button>Subscribe</Button>
                    </ButtonsContainer>
                </PayBlock>
            </PayWrapper>
        </Container>

    );
};

export default BillingComponent;
