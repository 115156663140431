import React from "react";

const PasswordIcon: React.FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path id="icon" d="M12.75 6.75C13.8546 6.75 14.75 7.64542 14.75 8.75L14.75 14.75C14.75 15.8546 13.8546 16.75 12.75 16.75L2.75 16.75C1.64543 16.75 0.75 15.8546 0.75 14.75L0.75 8.75C0.75 7.64542 1.64543 6.75 2.75 6.75L12.75 6.75ZM3.75 6.75L3.75 4.75C3.75 2.54086 5.54086 0.75 7.75 0.75C9.95914 0.75 11.75 2.54086 11.75 4.75L11.75 6.75M7.75 11.75C7.19771 11.75 6.75 11.3023 6.75 10.75C6.75 10.1977 7.19771 9.75 7.75 9.75C8.30229 9.75 8.75 10.1977 8.75 10.75C8.75 11.3023 8.30229 11.75 7.75 11.75ZM7.75 11.75L7.75 13.75" stroke="#686B6E" strokeOpacity="1.000000" strokeWidth="1.500000" strokeLinejoin="round"/>
        </svg>
    );
};

export default PasswordIcon