import styled from "styled-components";
import {StyledInputProps} from "../../store/types";
export const Container = styled.div`
  position: absolute;
  justify-content: right;
  top: 195px;
  left: 340px;
  right: 10px;
  bottom: 10px;
  min-width: 70%;
  box-sizing: border-box;
  border-bottom: 1px solid rgb(9, 32, 39);
  box-shadow: 0 8px 10px -6px rgba(6, 7, 8, 0.06), 0px 25px 50px -12px rgba(6, 7, 8, 0.16);
`;

export const LeftBlock = styled.div`
  position: absolute;
  max-height: 100%;
  width: 50%;
  left: 0;
  top: 0;
  bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 0;
`;
export const NameBlock = styled.p`
  color: rgb(155, 156, 158);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.15px;
  text-align: center;
  `;
export const RightBlock = styled.div`
  position: absolute;
  width: 50%;
  right: 0;
  top: 0;
  bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 0;
`;
export const Input = styled.input<StyledInputProps>`
  position: relative;
  width: 70%;
  height: 48px;
  border-radius: 8px;
  background: rgb(26, 29, 33);
  color: white;
  transition: border-color 0.3s ease;
  outline: none;
  box-sizing: border-box;
  border: ${(props) => {
    if (props.$error) {
        return '1px solid rgb(208, 48, 47)';
    }
    if (!props.$warning && props.$hasText) {
        return '1px solid rgb(226, 111, 32)';
    } else {
        return '1px solid rgb(54, 58, 61)';
    }
}};
  padding-left: 20px;

  &:not(:placeholder-shown) {
    border-color: ${(props) => (props.$hasText && props.$warning ? 'green' : '1px solid rgb(226, 111, 32)')};
  }

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border: 1px solid transparent;
    border-radius: 8px;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
  }

  &:focus {
    border-color: rgb(130, 219, 247);
    box-shadow: 0 0 0 1px rgb(182, 240, 156), 0 0 5px 4px rgba(132, 220, 245, 0.24);
  }

  &:focus::before {
    border-color: rgba(132, 220, 245, 0.24);
  }

  @media (max-width: 768px) {
    padding-left: 45px;
  }
`;
export const Button = styled.button`
    position: relative;
    width: 90%;
    height: 48px;
    padding: 8px 24px;
    background-color: rgb(39, 133, 168);
    color: rgb(12, 17, 50);
    border: none;
    border-radius: 12px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;

    &:hover {
        background-color: rgb(32, 109, 138);
        color: rgb(12, 17, 50);
    }

    &:active {
        transform: scale(0.98);
        background-color: rgb(24, 82, 103);
        color: rgb(12, 17, 50);
    }
`;
