import {createAction} from "@reduxjs/toolkit";
import {
    Chat,
    IAuthResponse,
    ICreateChat,
    ICreateTask,
    IError, IMessage,
    IRegisterUser,
    ISendMessage,
    IUpdateUser,
    IUserLogin,
    TypeChat
} from "../types";
import {initializeApp} from "firebase/app";
import {
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    getAuth,
    GoogleAuthProvider, sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut, updateProfile
} from "firebase/auth";
import {get, getDatabase, ref, set, push} from 'firebase/database';
import {v4 as uuidv4} from 'uuid';
import {getAnalytics} from "firebase/analytics";
import firebaseConfig from "../../firebaseConfig.json";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getDatabase(app);
const analytics = getAnalytics(app);

const additionalUserInfo = {
    tasks: 0,
    balance: 0,
    status: "New",
    endDate: "2025-01-01 13:48:00.000000",
}
const authService = {
    async login(payload: IUserLogin): Promise<IAuthResponse | IError> {
        try {
            const response = await signInWithEmailAndPassword(auth, payload.email, payload.password);
            const userRef = ref(db, 'users/' + response.user.uid);
            const snapshot = await get(userRef);
            if (snapshot.exists()) {
                return {
                    user: response.user,
                    data: snapshot.val(),
                };
            } else {
                return {
                    user: response.user,
                }
            }
        } catch (error: IError | any) {
            return {
                code: error.code || 'unknown_error',
                message: error.message || 'Unknown error',
            }
        }
    },
    async googleLogin(): Promise<IAuthResponse | IError> {
        try {
            const provider = new GoogleAuthProvider();
            const response = await signInWithPopup(auth, provider)
            const userRef = ref(db, 'users/' + response.user.uid);
            const snapshot = await get(userRef);
            if (snapshot.exists()) {
                return {
                    user: response.user,
                    data: snapshot.val(),
                };
            } else {
                await set(userRef, additionalUserInfo);
                return {
                    user: response.user,
                };
            }
        } catch (error: IError | any) {
            return {
                code: error.code || 'unknown_error',
                message: error.message || 'Unknown error',
            }
        }
    },
    async facebookLogin(): Promise<IAuthResponse | IError> {
        try {
            const provider = new FacebookAuthProvider();
            const response = await signInWithPopup(auth, provider)
            const userRef = ref(db, 'users/' + response.user.uid);
            await set(userRef, additionalUserInfo);
            return {
                user: response.user,
            };
        } catch (error: IError | any) {
            return {
                code: error.code || 'unknown_error',
                message: error.message || 'Unknown error',
            }
        }
    },
    async resetPassword(email:string): Promise<any |IError> {
        try {
            await sendPasswordResetEmail(auth, email)
            return {
                message: 'Email sent',
            }
        } catch (error: IError | any) {
            return {
                code: error.code || 'unknown_error',
                message: error.message || 'Unknown error',
            }
        }
    },
    async register(payload: IRegisterUser): Promise<IAuthResponse | IError> {
        try {
            const response = await createUserWithEmailAndPassword(auth, payload.email, payload.password);
            const userRef = ref(db, 'users/' + response.user.uid);
            await set(userRef, additionalUserInfo);
            return {
                user: response.user,
            };
        } catch (error: IError | any) {
            return {
                code: error.code || 'unknown_error',
                message: error.message || 'Unknown error',
            };
        }
    },
    async createTask(credentials: ICreateTask) {
        try {
            const chat: Chat = {
                name: "General",
                members: [auth.currentUser!.uid],
                type: TypeChat.PUBLIC,
            }
            const taskId = uuidv4();
            const taskRef = ref(db, `tasks/${taskId}`);
            credentials.id = taskId;
            credentials.status = "New";
            credentials.created_at = new Date().toISOString();
            credentials.ownerId = auth.currentUser?.uid;
            await set(taskRef, credentials);
            await this.createChat({taskId,...chat});
            return {taskId};
        } catch (error: IError | any) {
            return {
                code: error.code || 'unknown_error',
                message: error.message,
            };
        }
    },
    async createChat(payload: ICreateChat) {
        try {
            const chat: Chat = {
                name: payload.name,
                members: [auth.currentUser!.uid],
                type: payload.type,
                messages: []
            }
            const taskRef = ref(db, `tasks/${payload?.taskId}/chats/`);
            await push(taskRef, chat);
            return chat;
        } catch (error: IError | any) {
            return {
                code: error.code || 'unknown_error',
                message: error.message,
            };
        }
    },
    async addBalance(userId: string) {
        try {
            const userRef = ref(db, `users/${userId}`);
            const snapshot = await get(userRef);
            if (snapshot.exists()) {
                const userData = snapshot.val();
                const newBalance = (userData.balance || 0) + 10;
                await set(userRef, {...userData, balance: newBalance});
            } else {
                console.log('Пользователь с указанным ID не найден в базе данных');
            }
        } catch (error: IError | any) {
            return {
                code: error.code || 'unknown_error',
                message: error.message || 'Unknown error',
            };
        }
    },
    async sendMessage(payload: ISendMessage) {
        try {
            const messageId = uuidv4();
            const message: IMessage = {
                messageId: messageId,
                text: payload.text,
                date: new Date().toISOString(),
                owner: auth.currentUser?.displayName || "Anonymous",
            }
            const messageRef = ref(db, `tasks/${payload?.taskId}/chats/${payload?.chatId}/messages/`);
            await push(messageRef, message);


        } catch (error: IError | any) {
            return {
                code: error.code || 'unknown_error',
                message: error.message || 'Unknown error',
            };
        }
    },
    async update(payload: IUpdateUser) {
        try {
            if (auth.currentUser) {
                await updateProfile(auth.currentUser, {
                    displayName: payload?.displayName,
                    photoURL: payload?.photoURL,
                });
            }
        } catch (error: IError | any) {
            return {
                code: error.code || 'unknown_error',
                message: error.message || 'Unknown error',
            };
        }
    },
    async logOut() {
        try {
            await signOut(auth);
            console.log("Logout successful");
        } catch (error) {
            console.log(error);
        }
    },

}

export const logout = createAction("auth/logout");

export default authService;
