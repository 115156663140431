import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  justify-content: right;
  top: 195px;
  left: 340px;
  right: 10px;
  bottom: 10px;
  min-width: 70%;
  box-sizing: border-box;
  border-bottom: 1px solid rgb(9, 32, 39);
  box-shadow: 0 8px 10px -6px rgba(6, 7, 8, 0.06), 0px 25px 50px -12px rgba(6, 7, 8, 0.16);
`;

export const Text = styled.p`
  position: fixed;
  top: 50%;
  left: 50%;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  color: aliceblue;
  transform: translate(-50%, -50%);
`;
