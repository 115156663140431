import styled, {keyframes} from "styled-components";
import MailIcon from "../../assets/MailIcon";
import PasswordIcon from "../../assets/PasswordIcon";
import {boolean, string} from "yup";

const gradientAnimation = keyframes`
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`;

export const Logo = styled.img`
  position: absolute;
  width: 120px;
  height: 80px;
  left: 50px;
  top: 50px;
  z-index: -100;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Text = styled.div`
    position: relative;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    color: aliceblue;
    @media (max-width: 768px) {
        font-size: 24px;
    };
`;

interface InputWrapperProps {
    $hasText?: string;
    $error?: boolean;
    $warning?: boolean;
}

export const InputContainer = styled.div<InputWrapperProps>`
    width: 100%;
    outline: none;
    padding-bottom: 30px;
    input {
        width: 90%;
        position: relative;
        height: 48px;
        border-radius: 8px;
        background: rgb(26, 29, 33);
        color: white !important;
        transition: border-color 0.3s ease;
        outline: none;
        box-sizing: border-box;
        border: ${(props) => {
            if (props.$error) {
                return '1px solid rgb(208, 48, 47)';
            }
            if (!props.$warning && props.$hasText) {
                return '1px solid rgb(226, 111, 32)';
            } else {
                return '1px solid rgb(54, 58, 61)';
            }
        }};
        padding-left: 20px;

        &:not(:placeholder-shown) {
            border-color: ${(props) => (props.$hasText && props.$warning ? 'green' : '1px solid rgb(226, 111, 32)')};
        }

        &:before {
            content: '';
            position: absolute;
            top: -1px;
            right: -1px;
            bottom: -1px;
            left: -1px;
            border: 1px solid transparent;
            border-radius: 8px;
            transition: border-color 0.3s ease;
            box-sizing: border-box;
        }

        &:focus {
            border-color: rgb(130, 219, 247);
            box-shadow: 0 0 0 1px rgb(182, 240, 156), 0 0 5px 4px rgba(132, 220, 245, 0.24);
        }

        &:focus::before {
            border-color: rgba(132, 220, 245, 0.24);
        }
    }
`;

export const IconWrapper = styled.button`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  background: none;
  border: none;
`;

export const EmailIconWrapper = styled(IconWrapper)`
  top: 60%;
`;

export const PasswordIconWrapper = styled(IconWrapper)`
  top: 58%;
`;

export const MailIconStyled = styled(MailIcon)`
  width: 24px;
  height: 24px;
`;

export const PasswordIconStyled = styled(PasswordIcon)`
  width: 24px;
  height: 24px;
`;
export const ToggleRememberContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 90%;
    @media (max-width: 768px) {
        text-align: left;
        justify-content: center;
        flex-direction: column;
    }
`;
export const ForgotPasswordText = styled.div`
  position: relative;
  background: linear-gradient(225.00deg,
  rgb(48, 69, 201),
  rgb(80, 129, 194),
  rgb(101, 190, 218),
  rgb(80, 129, 194),
  rgb(72, 90, 197),
  rgb(80, 129, 194),
  rgb(101, 190, 218));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 24px;
  letter-spacing: 0.15px;
  cursor: pointer;
  background-size: 200% 200%;
  animation: ${gradientAnimation} 10s ease infinite;
  z-index: -100;
  @media (max-width: 768px) {
    -webkit-text-fill-color: rgb(205, 206, 207);
    left: 68%;
  }
    @media (max-width: 1600px) {
        margin-left: 310px;
    }
    @media (max-width: 1280px) {
        margin-left: 210px;
    }
    @media (max-width: 768px) {
        margin-left: -90px;
    }
`;
export const TextGradient = styled.div`
  background: linear-gradient(225.00deg, rgb(68, 206, 202), rgb(37, 128, 128), rgb(70, 81, 145), rgb(135, 221, 238), rgb(182, 240, 156));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 300% 300%;
  display: inline;
  margin-left: 10px;
  animation: ${gradientAnimation} 30s ease infinite;
  @media (max-width: 768px) {
    background: #0b3434;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
export const RememberText = styled.div`
  position: absolute;
  left: 50px;
  color: rgb(205, 206, 207);
  font-size: 14px;
    white-space: nowrap;
  @media (max-width: 768px) {
    left: 50px;
  }
`;
export const Button = styled.button`
    position: relative;
    width: 90%;
    height: 48px;
    padding: 8px 24px;
    background-color: rgb(39, 133, 168);
    color: rgb(12, 17, 50);
    border: none;
    border-radius: 12px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;

    &:hover {
        background-color: rgb(32, 109, 138);
        color: rgb(12, 17, 50);
    }

    &:active {
        transform: scale(0.98);
        background-color: rgb(24, 82, 103);
        color: rgb(12, 17, 50);
    }
`;

export const DividerContainer = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 60px;
    align-items: center;
    z-index: -100;
`;

export const DividerLine = styled.div`
  position: static;
  width: 30%;
  height: 0;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0 16px;
  transform: rotate(-180.00deg);
  border: 1px solid rgb(54, 58, 61);
  @media (max-width: 768px) {
      width: 15%;
  }
`;

export const DividerText = styled.div`
    width: 100%;
    height: 18px;
    margin: 0 auto;
    color: rgb(104, 107, 110);
    font-size: 14px;
    font-weight: 500;
    z-index: -100;
`;


export const SocialLoginButton = styled.button`
    width: 236px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 30px;
    background-color: rgb(26, 29, 33);
    color: rgb(104, 107, 110);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: background-color 0.3s ease, color 0.3s ease;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

    &:hover {
        background-color: rgb(54, 58, 61);
        color: rgb(155, 156, 158);
    }

    &:active {
        transform: scale(0.98);
        background-color: rgb(104, 107, 110);
        color: rgb(205, 206, 207);
    }
    @media (max-width: 768px) {
        margin: 0 10px;
        width: 100%;
    }
`;
export const SmallText = styled.div`
    position: absolute;
    bottom: 30px;
    color: rgb(172, 171, 171);
    @media (max-width: 768px) {
        bottom: 10px;
    }
`;
export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 11;
    color: white;
`;
export const CrossIconWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 10px;
  height: 10px;
  cursor: pointer;
`;
export const SubtitleError = styled.h6`
    padding: 10px 0;

    font-size: 14px;
    line-height: 140%;

    color: red;
`;

export const Title = styled.h2`
  color: #2c3e50;
`;

export const Subtitle = styled.h3`
  color: #3498db;
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const ListItem = styled.li`
  margin-bottom: 10px;
`;

export const Highlight = styled.strong`
    color: rgb(39, 133, 168);
`;
export const DialogAboutTask = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    width: 100%;
    gap: 20px;
    border-radius: 16px;
    background: rgba(26, 29, 33, 0.96);
    padding: 40px;
    z-index: 12;
`;
