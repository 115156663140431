import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  justify-content: right;
  top: 195px;
  left: 340px;
  right: 10px;
  bottom: 10px;
  min-width: 70%;
  box-sizing: border-box;
  border-bottom: 1px solid rgb(9, 32, 39);
  box-shadow: 0 8px 10px -6px rgba(6, 7, 8, 0.06), 0px 25px 50px -12px rgba(6, 7, 8, 0.16);
`;

export const Text = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  color: aliceblue;
  transform: translate(-50%, -50%);
`;

export const BalanceBlock = styled.div`
  position: fixed;
  right: 0;
  width: 200px;
  height: 113px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 24px 10px 24px;
  margin: 0 10px;
  border-radius: 20px;
  background: rgb(14, 23, 27);
  opacity: 0.8;
`;
export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%; 
`;
export const PayContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100px;
  width: 90%;
`;

export const BalanceItem = styled.div`
  position: absolute;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left; 
  width: fit-content; 
  align-self: flex-start; 
`;

export const YourSecondItem = styled.div`
  color: rgb(155, 156, 158);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: right;
  width: fit-content;
  align-self: flex-end;
`;
export const CentralText = styled.div`
  position: static;
  width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 5px auto;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: center;
`;
export const CentralTextWrapper = styled.div`
  height: 35%;
`;

export const PayWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

`;
export const Title = styled.div`
  position: static;
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 4px;
  background: linear-gradient(45.00deg, rgb(130, 219, 247), rgb(182, 240, 156));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: center;
`;
export const PayBlock = styled.div`
  position: relative;
  width: 25%;
  height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 24px;
  margin: 0 10px;
  border-radius: 20px;
  background: rgb(14, 23, 27);
  opacity: 0.8;
`;

export const ButtonFree = styled.button`
  width: 120px;
  height: 48px;
  background-color: rgb(26, 29, 33);
  color: rgb(104, 107, 110);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: rgb(54, 58, 61);
    color: rgb(155, 156, 158);
  }
  &:active{
    transform: scale(0.98);
    background-color: rgb(104, 107, 110);
    color: rgb(205, 206, 207);
  }
`;
export const Button = styled.button`
  position: static;
  width: 120px;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  margin: 28px 20px;
  background-color: rgb(39, 133, 168);
  color: rgb(12, 17, 50);
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;

  &:hover {
    background-color: rgb(32, 109, 138);
    color: rgb(12, 17, 50);
  }

  &:active {
    transform: scale(0.98);
    background-color: rgb(24, 82, 103);
    color: rgb(12, 17, 50);
  }
  @media (max-width: 768px) {
    width: 80%;
    margin: 0 auto;
  }
`;
export const ButtonsContainer = styled.div`
  position: relative;
  height: 48px;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const SecondItem = styled.div`
  color: rgb(155, 156, 158);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: right;
  width: fit-content;
  align-self: flex-end;
`;
