export enum routes {
  HOME = "/home",
  REGISTER = "/register",
  LOGIN = "/login",
  REGISTRATION = "/registration",
  CONFIRM = "/confirm",
  CHANGEPASSWORD = "/changepassword",
  FORGOTPASSWORD = "/forgotpassword",
  NOT_FOUND = "*",
  TASKS = "/home/tasks",
}
