import React from "react";
import {
  AllTasks,
  BottomBlock,
  Button,
  ButtonsBlock,
  Col,
  Container,
  DoubleBlock,
  DropDownBlock,
  Element,
  Line,
  SelectUserBlock,
  SendButton,
  SmallTitleText,
  StatusButton, Table,
  Text,
  Title,
  TitleText,
  Toggle,
  TopBlock,
  UserAvatar,
  UserBlock,
  UserLink,
  UserName,
  UsersTitle
} from "./styles";
import { Outlet, useNavigate } from "react-router-dom";
import { DefaultAvatar, DotsIcon } from "../../assets";
import { ImageStyled } from "../ChatBlock/styles";
import { InvitedMember, OnlineUsers, RoleToInvite, Task, TypeTask } from "../../store/types";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { CrossIconWrapper, DialogContentProduct, Overlay } from "../TopBlock/styles";
import CircleIcon from "../../assets/CircleIcon";
import { Input } from "../../style/GlobalStyle";
import AddowDownIcon from "../../assets/AddowDownIcon";
import { getAuth } from "firebase/auth";
import { get, getDatabase, ref, update } from "firebase/database";
import DangerComponent, { DangerMessageType } from "../../style/DangerComponent";
import OkIcon from "../../assets/OkIcon";
import DialogContentSettingsTask from "./DialogContentSettingsTask";

const typeMapping: Record<string, TypeTask> = {
  "web": TypeTask.WEB_APPLICATION,
  "problems": TypeTask.PROBLEM_SOLVING,
  "mobile": TypeTask.MOBILE_APP,
  "startups": TypeTask.STARTUPS
};
const TasksBlock: React.FC = () => {

  const userTasks: Task[] | null = useSelector((state: RootState) => state.app?.tasks);
  const path = window.location.pathname;
  const parts = path.split("/");
  const type = parts[parts.length - 1];
  const [selected, setSelected] = React.useState("all");
  const navigate = useNavigate();
  const [showBlock, setShowBlock] = React.useState(false);
  const [selectedTaskId, setSelectedTaskId] = React.useState<string>("");

  const [showNotification, setShowNotification] = React.useState(false);

  const closeNotificationHandler = () => {
    setShowNotification(false);
  };

  return (
    <Container>
      {showNotification ?
        <DangerComponent
          show={showNotification}
          close={closeNotificationHandler}
          message={`User added to task.`}
          color="green"
          icon={<OkIcon />}
          type={DangerMessageType.Ok}
        /> : null}
      <Outlet />
      <TopBlock> <Text>Created or invited Task {type}</Text><Button style={{ margin: 0 }}>Export CSV</Button></TopBlock>
      <Line />
      <ButtonsBlock>
        <Button onClick={() => setSelected("all")}>All</Button>
        <Button onClick={() => setSelected("awaiting")}>Awaiting</Button>
        <Button onClick={() => setSelected("expired")}>Expired</Button>
      </ButtonsBlock>
      <Line />
      <Table>
        <Toggle />
        <Col>Name task</Col>
        <Col>Status</Col>
        <Col>Technologies</Col>
        <Col>Budget</Col>
        <Col>Deadlines</Col>
        <ImageStyled src={DotsIcon} />
      </Table>
      <Line />
      <AllTasks style={{ overflow: "auto", maxHeight: "750px" }}>
        {Object.values(userTasks || {}).map((task, index) => {
          const taskType = typeMapping[type];

          if (task?.type === taskType) {
            const chatId = Object.keys(task.chats || {})[0];

            return (
              <React.Fragment key={index}>
                <Element onClick={() => navigate(`/home/chat/${task.id}/${chatId}`)}>
                  <Toggle $isChecked={false} onClick={(e) => {
                    e.stopPropagation();
                    console.log("check");
                  }} />
                  <Title>{task.name}</Title>
                  <Title>Status</Title>
                  <Title>{task.technologies}</Title>
                  <Title>{task.budget}</Title>
                  <Title>{task.deadline}</Title>
                  <ImageStyled src={DotsIcon} onClick={(e) => {
                    e.stopPropagation();
                    setShowBlock(true);
                    setSelectedTaskId(task?.id || "");
                  }} />
                </Element>
                <Line />
                {showBlock && (
                  <Overlay>
                    <DialogContentSettingsTask task={task as Task} setShowBlock={setShowBlock} />
                  </Overlay>
                )}
              </React.Fragment>
            );

          } else {
            return null;
          }
        })}

      </AllTasks>


    </Container>

  );
};

export default TasksBlock;
