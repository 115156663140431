import styled from "styled-components";
import {string} from "yup";
interface StyledInputProps {
  hasText?: string;
  error?: boolean;
  warning?: boolean;
}

export const Block = styled.div`
  position: absolute;
  justify-content: right;
  top: 12px;
  left: 340px;
  right: 10px;
  min-width: 70%;
  height: 97px;
  padding: 12px 0 22px 20px;
  box-sizing: border-box;
  border-radius: 24px 24px 0 0;
  border-bottom: 1px solid rgb(9, 32, 39);
  box-shadow: 0 8px 10px -6px rgba(6, 7, 8, 0.06), 0px 25px 50px -12px rgba(6, 7, 8, 0.16);
  background: rgb(14, 23, 27);
`;
export const MenuBlock = styled.div`
  position: absolute;
  display: flex;
  min-width: 70%;
  height: 80px;
  top: 109px;
  left: 340px;
  right: 10px;
  padding: 12px 0 22px 20px;
  box-sizing: border-box;
  border-radius: 0 0 24px 24px;
  border-bottom: 1px solid rgb(9, 32, 39);
  box-shadow: 0 8px 10px -6px rgba(6, 7, 8, 0.06), 0px 25px 50px -12px rgba(6, 7, 8, 0.16);
  background: rgb(14, 23, 27);
`;


export const TextBlock = styled.div`
  width: 113px;
  height: 32px;
  margin: 4px 10px;
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0;
  text-align: center;
`;
export const SmallTextBlock = styled.div`
  width: 429px;
  height: 20px;
  margin: 4px 10px;
  color: rgb(155, 156, 158);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.15px;
  text-align: center;
`;

export const MenuItem = styled.div<{ $link: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 50px;
  padding: 0 20px;
  border-radius: 8px;
  position: relative;

  ${({ $link }) =>
      $link &&
      `
    & * {
      color: rgb(182, 240, 156) !important;
    }

    &::after {
      content: '';
      position: absolute;
      width: 83px;
      height: 4px;
      flex: none;
      flex-grow: 0;
      margin: 80px 12px 0;
      border-radius: 4px 4px 0 0;
      box-shadow: 0 4px 6px -4px rgba(182, 240, 156, 0.16), 0px 10px 15px -3px rgba(182, 240, 156, 0.16);
      background: rgb(182, 240, 156);
    }
  `}
`;
export const TextMenu = styled.div`
  color: rgb(232, 233, 233);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.15px;
  text-align: center;
  margin-left: 10px;

  &:active {
    background: linear-gradient(145.32deg, rgba(114,152,152,0.16) -30.47%, rgba(204, 235, 235, 0) 100%);
    cursor: pointer;
    transform: scale(0.98);
  }
`;

export const ShareBar = styled.div`
  position: absolute;
  right: 70px;
  top: 32px;
  width: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0 10px;
  cursor: pointer;
  `;

export const ShareBlock = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  top: 43px;
  right: 40px;
  width: 40px;
  color: rgb(104, 107, 110);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.15px;
`;
export const UsersOnlineBlock = styled.div`
  position: absolute;
  top: 24px;
  right: 70px;
  width: 370px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    margin-right: -15px;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;
export const AvatarsOnline = styled.div`
  position: relative;
  display: inline-block;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 4px solid rgb(9, 32, 39);
  cursor: pointer;
`;
export const CountOnline = styled.div`
  color: rgb(104, 107, 110);
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.15px;
  text-align: left;
  position: absolute;
  top: 47px;
  right: 209px;
  z-index: 100;
`;

export const StatusOnline = styled.img`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 0;
  right: 0;
  box-shadow: 0 0 12px 0 rgba(74, 201, 126, 0.48);
`;

export const AvatarOnline = styled.img`
  height: 32px;
  width: 32px;
  position: relative;
  border-radius: 50%;
`;
export const AvatarOnlineCount = styled.div<{ $count?: number }>`
  height: 32px;
  width: 32px;
  position: relative;
  border-radius: 50%;

  &::after {
    content: '${props => props.$count}';
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: rgb(56, 58, 60);
    color: white;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
`;

export const MenuImage = styled.img`
  height: 16px;
  width: 16px;
  position: relative;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11;
`;

export const DialogContentProduct = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 430px;
  width: 100%;
  gap: 20px;
  border-radius: 16px;
  background:  rgba(26, 29, 33, 0.96);
  padding: 40px;
  z-index: 12;
`;
export const CrossIconWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 10px;
  height: 10px;
  cursor: pointer;
`;
export const MessageBlock = styled.div`
  position: relative;
  padding: 16px;
`;
export const Input = styled.textarea<StyledInputProps>`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding: 10px;
  min-height: 48px;
  border-radius: 8px;
  background: rgb(26, 29, 33);
  color:  rgb(155, 156, 158);
  transition: border-color 0.3s ease;
  outline: none;
  box-sizing: border-box;
  overflow: auto;
  resize: none;
  border: ${(props) => {
  if (props.error) {
    return '1px solid rgb(208, 48, 47)';
  }
  if (!props.warning && props.hasText) {
    return '1px solid rgb(226, 111, 32)';
  } else {
    return '1px solid rgb(54, 58, 61)';
  }
}};

  &:not(:placeholder-shown) {
    border-color: ${(props) => (props.hasText && props.warning ? 'green' : '1px solid rgb(226, 111, 32)')};
  }

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border: 1px solid transparent;
    border-radius: 8px;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
  }

  &:focus {
    border-color: rgb(130, 219, 247);
    box-shadow: 0 0 0 1px rgb(182, 240, 156), 0 0 5px 4px rgba(132, 220, 245, 0.24);
  }

  &:focus::before {
    border-color: rgba(132, 220, 245, 0.24);
  }

  @media (max-width: 768px) {
    padding-left: 45px;
  }
`;
