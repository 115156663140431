import React, { useRef, useState } from "react";
import {
  BigInput,
  BottomBlock, Button,
  Container, CustomScrollBlock, DateContainer, DropdownItem, Input, InputBlock, InputContainer, Text, TopBlock
} from "./styles";
import { CrossIconWrapper, DialogContentProduct, MessageBlock, Overlay } from "../TopBlock/styles";
import CircleIcon from "../../assets/CircleIcon";
import { ICreateTask, TypeTask } from "../../store/types";
import { useAppDispatch } from "../../store/hooks";
import { CreateTaskAsync, logoutFunc } from "../../store/slices/userSlice";
import DangerComponent, { DangerMessageType } from "../../style/DangerComponent";
import OkIcon from "../../assets/OkIcon";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { ErrorMessage, Field, Form, Formik, FormikProps, useField } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import { ErrorBlock, SubtitleError } from "../LogInForm/styles";
import { isValidDateValue } from "@testing-library/user-event/dist/utils";
import DatePicker from "react-datepicker";


const CreateTaskBlock: React.FC = () => {
  const collaboratorsArray = ["Pair Programming", "Collaborative Coding", "Code Review", "Team Collaboration", "Library and Tool Sharing", "Cross-functional Teams"];
  const [newTask, setNewTask] = useState<ICreateTask>({
    name: "New test task",
    type: TypeTask.WEB_APPLICATION,
    level: 5,
    area: "Test area",
    deadline: new Date(),
    technologies: ["Technology test"],
    collaboration: collaboratorsArray[0],
    members: 2,
    message: "New test message",
    budget: 500
  });
  const typesArray: TypeTask[] = [TypeTask.WEB_APPLICATION, TypeTask.PROBLEM_SOLVING, TypeTask.MOBILE_APP, TypeTask.STARTUPS];
  const levelsArray = ["1", "2", "3", "4", "5"];
  const [isLevelsOpen, setIsLevelsOpen] = useState(false);
  const [isOpenType, setIsOpenType] = useState(false);
  const [isCollaboratorsOpen, setIsCollaboratorsOpen] = useState(false);
  const [isAddTechnology, setIsAddTechnology] = useState(false);
  const [filteredCollaborators, setFilteredCollaborators] = useState<string[]>(collaboratorsArray);
  const [selectedCollaborators, setSelectedCollaborators] = useState("");
  const [isValidDate, setIsValidDate] = useState(true);
  const dispatch = useAppDispatch();
  const [showNotification, setShowNotification] = React.useState(false);
  const error = useSelector((state: RootState) => state.app.error);
  const formikRef = useRef<FormikProps<ICreateTask>>(null);
  const navigate = useNavigate();

  const createTaskClick = (values: ICreateTask) => {
    dispatch(CreateTaskAsync(values))
      .then((result: any) => {
        if (result.meta.requestStatus === "fulfilled") {
          // navigate("/");
          return;
        } else {
          switch (result.payload?.code) {
          case "wrong data!":
          case "auth/user-disabled":
          case "auth/user-not-found":
          case "auth/wrong-password":
          case "auth/invalid-credential":
            setShowNotification(true);
            break;
          default:
            console.log("An unknown error occerred:", result.payload.code);
          }
        }
      });
  };

  const closeNotificationHandler = () => {
    setShowNotification(false);
  };

  const handleCollaboratorsInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputText = event.target.value;
    setSelectedCollaborators(inputText);
    setFilteredCollaborators(collaboratorsArray.filter(item =>
      item.toLowerCase().includes(inputText.toLowerCase())
    ));
  };

  const handleLevelsToggleDropdown = () => {
    setIsLevelsOpen(!isLevelsOpen);
  };

  const handleToggleTypeDropdown = () => {
    setIsOpenType(!isOpenType);
  };

  const handleToggleCollaboratorsDropdown = () => {
    setIsCollaboratorsOpen(!isCollaboratorsOpen);
  };

  const handleTypeItemClick = (item: TypeTask, setFieldValue: any) => {
    setFieldValue("type", item);
    setIsOpenType(false);
  };

  const handleLevelsItemClick = (item: string, setFieldValue: any) => {
    setFieldValue("level", parseInt(item));
    setIsLevelsOpen(false);
  };

  const handleCollaboratorsItemClick = (item: string, setFieldValue: any) => {
    setSelectedCollaborators(item);
    setFieldValue("collaboration", item);
    setIsCollaboratorsOpen(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    const dateValue = event.target.value;
    setFieldValue("deadline", dateValue);
    const selectedDate = new Date(dateValue);
    const currentDate = new Date();
    setIsValidDate(selectedDate >= currentDate);
  };

  const taskCredentials: ICreateTask = {
    name: "",
    type: TypeTask.WEB_APPLICATION,
    level: 0,
    area: "",
    collaboration: "",
    deadline: new Date(),
    budget: 0,
    technologies: [],
    members: 0,
    message: ""
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Task name is required"),
    type: Yup.string().required("Type is required"),
    level: Yup.number().required("Level is required").min(1).max(5),
    area: Yup.string().required("Area is required"),
    collaboration: Yup.string().required("Collaboration is required"),
    deadline: Yup.date().required("Deadline is required").min(new Date(), "Deadline cannot be in the past"),
    budget: Yup.number().required("Budget is required").min(0),
    members: Yup.number().required("Members limit is required").min(1),
    message: Yup.string().required("Message is required")
  });

  return (
    <Container>
      {showNotification ?
        <DangerComponent
          show={showNotification}
          close={closeNotificationHandler}
          message={"Task was created"}
          color="green"
          icon={<OkIcon />}
          type={DangerMessageType.Ok}
        /> : null}
      <Formik initialValues={taskCredentials} validationSchema={validationSchema}
              onSubmit={(values) => createTaskClick(values)}
              innerRef={formikRef}>
        {({ errors, touched, setFieldValue }) => (
          <Form style={{ width: "100%" }}>
            <ErrorBlock>
              <ErrorMessage name="name" component="p" className="error-message" />
              <ErrorMessage name="type" component="p" className="error-message" />
              <ErrorMessage name="level" component="p" className="error-message" />
              <ErrorMessage name="area" component="p" className="error-message" />
              <ErrorMessage name="collaboration" component="p" className="error-message" />
              <ErrorMessage name="deadline" component="p" className="error-message" />
              <ErrorMessage name="budget" component="p" className="error-message" />
              <ErrorMessage name="members" component="p" className="error-message" />
              <ErrorMessage name="message" component="p" className="error-message" />
              {error && <SubtitleError>{error.code}</SubtitleError>}
            </ErrorBlock>
            <TopBlock>
              <InputBlock>
                <Text>Name task</Text>
                <InputContainer $error={!!errors.name && touched.name}>
                  <Field as={Input} type="name" name="name" placeholder="Task name" />
                </InputContainer>
              </InputBlock>
              <InputBlock>
                <Text>Type</Text>
                <InputContainer $error={!!errors.type && touched.type}>
                  <Field as={Input} type="text" name="type" placeholder="Type"
                         onClick={() => setIsOpenType(!isOpenType)}
                         readOnly
                  />
                  {isOpenType && (
                    <CustomScrollBlock>
                      {typesArray.map((item, index) => (
                        <DropdownItem
                          key={index}
                          onClick={() => {
                            handleTypeItemClick(item, setFieldValue);
                          }}
                        >
                          {item}
                        </DropdownItem>
                      ))}
                    </CustomScrollBlock>
                  )}
                </InputContainer>
              </InputBlock>
              <InputBlock>
                <Text>Level</Text>
                <InputContainer $error={!!errors.level && touched.level}>
                  <Field as={Input} type="number" name="level" placeholder="Level"
                         onClick={() => setIsLevelsOpen(!isLevelsOpen)}
                         readOnly
                  />
                  {isLevelsOpen && (
                    <CustomScrollBlock>
                      {levelsArray.map((item, index) => (
                        <DropdownItem
                          key={index}
                          onClick={() => {
                            handleLevelsItemClick(item, setFieldValue);
                          }}
                        >
                          {item}
                        </DropdownItem>
                      ))}
                    </CustomScrollBlock>
                  )}
                </InputContainer>
              </InputBlock>
              <InputBlock>
                <Text>Area</Text>
                <InputContainer $error={!!errors.area && touched.area}>
                  <Field as={Input} type="area" name="area" placeholder="Area" />
                </InputContainer>
              </InputBlock>
            </TopBlock>
            <BottomBlock>
              <InputBlock>
                <Text>Collaboration</Text>
                <InputContainer $error={!!errors.collaboration && touched.collaboration}>
                  <Field as={Input} type="text" name="collaboration" placeholder="Collaboration"
                         onChange={handleCollaboratorsInputChange}
                         onClick={handleToggleCollaboratorsDropdown}
                         value={selectedCollaborators}
                         readOnly
                  />
                  {isCollaboratorsOpen && (
                    <CustomScrollBlock>
                      {filteredCollaborators.map((item, index) => (
                        <DropdownItem
                          key={index}
                          onClick={() => {
                            handleCollaboratorsItemClick(item, setFieldValue);
                          }}
                        >
                          {item}
                        </DropdownItem>
                      ))}
                    </CustomScrollBlock>
                  )}
                </InputContainer>
              </InputBlock>
              <InputBlock>
                <Text>Deadlines</Text>
                <DateContainer $error={!!errors.deadline && touched.deadline}>
                  <DatePicker
                    selected={formikRef.current?.values?.deadline}
                    dateFormat="MMMM d, yyyy"
                    onChange={date => setFieldValue("deadline", date)}
                  />
                  {/*<Field as={Input}*/}
                  {/*       type="date" name="deadline" placeholder="Deadline"*/}
                  {/*       hasText={touched.deadline?.toString() || ""}*/}
                  {/*       value={isValidDateValue}*/}
                  {/*       onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e,setFieldValue)}*/}
                  {/*/>*/}
                </DateContainer>
              </InputBlock>
              <InputBlock>
                <Text>Reward or Budget</Text>
                <InputContainer $error={!!errors.budget && touched.budget}>
                  <Field as={Input}
                         type="text" name="budget" placeholder="0$"
                         hasText={touched.budget?.toString() || ""}
                  />
                </InputContainer>
              </InputBlock>
              <InputBlock>
                <Text>Technologies</Text>
                <InputContainer $error={!!errors.technologies && touched.technologies}>
                  <Field as={Input} type="text" name="technologies" placeholder="Technologies"
                         onClick={() => setIsAddTechnology(true)}
                         readOnly
                  />
                </InputContainer>
              </InputBlock>
              <InputBlock>
                <Text>Member Limit</Text>
                <InputContainer $error={!!errors.members && touched.members}>
                  <Field as={Input}
                         type="number" name="members" placeholder="Member limit"
                         hasText={touched.members?.toString() || ""}
                  />
                </InputContainer>
              </InputBlock>
            </BottomBlock>
            <MessageBlock>
              <Text>Message:</Text>
              <InputContainer>
                <Field as={BigInput}
                       type="text" name="message" placeholder="Message"
                       value={newTask.message}
                       hasText={newTask.message}
                />
              </InputContainer>
            </MessageBlock>
            <Button type="submit">Create task</Button>
          </Form>
        )}
      </Formik>
      {isAddTechnology && (
        <Overlay>
          <DialogContentProduct>
            <CrossIconWrapper>
              <CircleIcon onClick={() => setIsAddTechnology(false)} />
            </CrossIconWrapper>
          </DialogContentProduct>
        </Overlay>
      )}
    </Container>

  );
};

export default CreateTaskBlock;
