import React from "react";
import {
    Button,
    Container, Input, LeftBlock, NameBlock, RightBlock,
} from "./styles";
import {useAppDispatch} from "../../store/hooks";
import {UpdateUserAsync} from "../../store/slices/userSlice";
import {getAuth, sendEmailVerification} from "firebase/auth";


const PersonalSettingsBlock: React.FC = () => {
    const auth = getAuth();
    const dispatch = useAppDispatch();
    const user = auth.currentUser;
    const [displayName, setDisplayName] = React.useState<string>(user?.displayName || "")
    const handleUpdate = () => {
        dispatch(UpdateUserAsync({displayName, photoURL: "https://gravatar.com/avatar/a3eb810471377dd8272ddbf4f426d5d1?s=400&d=identicon&r=x"}))
    }
    const handleVerify = () => {
        if (auth.currentUser) {
            sendEmailVerification(auth.currentUser)
                .then(() => {
                    console.log("Verification email sent");
                })
                .catch((error) => {
                    console.error("Error sending verification email:", error);
                })
        }    }
    return (
        <Container>
            <LeftBlock>
                <NameBlock>Display Name</NameBlock>
                <Input $warning={false} $hasText={""} $error={false} value={displayName}
                       onChange={(e) => setDisplayName(e.target.value)}></Input>
                {/*<NameBlock>Phone number</NameBlock>*/}
                {/*<Input warning={false} hasText={""} error={false}></Input>*/}
                {/*<NameBlock>Skills</NameBlock>*/}
                {/*<Input warning={false} hasText={""} error={false}></Input>*/}
                {/*<NameBlock>Links</NameBlock>*/}
                {/*<Input warning={false} hasText={""} error={false}></Input>*/}
                {/*<NameBlock>County</NameBlock>*/}
                {/*<Input warning={false} hasText={""} error={false}></Input>*/}
                {/*<NameBlock>About me</NameBlock>*/}
                {/*<Input style={{height: "100px"}} warning={false} hasText={""} error={false}></Input>*/}
                <Button onClick={handleUpdate}>Button</Button>
                <Button onClick={handleVerify}>sendVerification</Button>

            </LeftBlock>
            <RightBlock>
                <NameBlock>Popular skills</NameBlock>
                <Input $warning={false} $hasText={""} $error={false}></Input>
                <NameBlock>Popular Links</NameBlock>
                <Input $warning={false} $hasText={""} $error={false}></Input>
            </RightBlock>

        </Container>

    );
};

export default PersonalSettingsBlock;
