import React, {useEffect, useRef, useState} from "react";
import {
    AdminInfo,
    Avatar,
    BottomButtonWrapper,
    BottomGradientBlock,
    Button,
    ButtonClick,
    ButtonsWrapper,
    ChatBlock,
    ChatBlockWrapper,
    Container,
    CopyIconWrapper,
    CreatePrivateChatButton,
    CreatePrivateChatText,
    Date,
    DescriptionText,
    FixedMessage,
    GrayLabel,
    HeadWrapper,
    IconStyled,
    ImageStyled,
    InformationBlock,
    InputChat,
    LabelsWrapper,
    ListContainer,
    MembersBlock,
    MembersOnlineBlock,
    Message,
    MessageBlock,
    NameBlock,
    NameChatBlock,
    NameChatText,
    Names,
    NewMessages,
    NewPublicMessages,
    PlanetIconStyled,
    PlusIconStyled,
    PrivateChatsBlock,
    PublicChatsBlock,
    ReplyButton,
    RightBlockFixed,
    SendMessageButtonClick,
    Text,
    UserAvatar,
    UserBlock,
    UserName,
    UserOfflineBlock,
    UserStatus,
    UserStatusBlock,
    UserStatusWhere,
    WhiteLabel,
} from "./styles";
import {
    CopyIcon,
    DefaultAvatar,
    microphone,
    PlanetIcon,
    Screpka,
    SendMessageButton,
} from "../../assets";
import {ArrowIconStyled} from "../MenuBlock/styles";
import {ICreateChat, IMessage, Task, TypeChat} from "../../store/types";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {CreateChatAsync, SendMessageAsync} from "../../store/slices/userSlice";
import {getAuth} from "firebase/auth";
import {getDatabase, onValue, ref} from "firebase/database";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {useNavigate, useParams} from "react-router-dom";
import { format, parseISO } from "date-fns";


const ChatComponent: React.FC = () => {
    const onlineUsers = useAppSelector(state => state.app?.onlineUsers);
    const stateUser = useAppSelector(state => state.app?.user)
    const [informationBlockOpen, setInformationBlockOpen] = useState(false);
    const [publicChatsBlockOpen, setPublicChatsBlockOpen] = useState(false);
    const [privateChatsBlockOpen, setPrivateChatsBlockOpen] = useState(false);
    const [currentlyOnlineBlockOpen, setCurrentlyOnlineBlockOpen] = useState(false);
    const [currentlyOfflineBlockOpen, setCurrentlyOfflineBlockOpen] = useState(false);
    const [chatsOrMembers, setChatsOrMembers] = useState(true);
    const messageBlockRef = useRef<HTMLDivElement | null>(null);
    const [namePublicChat, setNamePublicChat] = useState<string>("");
    const [namePrivateChat, setNamePrivateChat] = useState<string>("");
    const [text, setText] = useState<string>("");
    const userTasks: Task[] | null = useSelector((state: RootState) => state.app?.tasks || null);
    const usersArray = Object.values(onlineUsers || {});
    const [selectedTask, setSelectedTask] = useState<Task | null>(null);
    const auth = getAuth();
    const user = auth.currentUser;
    const dispatch = useAppDispatch();
    const [chatMessages, setChatMessages] = useState<IMessage[]>([]);
    const db = getDatabase();
    const {id, chatId} = useParams<{ id: string; chatId: string }>();
    const navigate = useNavigate();
    const handleChatClick = (chatId: string) => {
        navigate(`/home/chat/${id}/${chatId}`);
    };
    useEffect(() => {
        if (userTasks) {
            setSelectedTask(userTasks.find(task => task.id === id) || null);
        } else {
            setSelectedTask(null);
        }

        if (id && chatId) {
            const messagesRef = ref(db, `tasks/${id}/chats/${chatId}/messages`);
            const unsubscribe = onValue(messagesRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    const messagesArray: IMessage[] = Object.values(data);
                    setChatMessages(messagesArray);
                } else {
                    setChatMessages([]);
                }
            });

            if (messageBlockRef.current) {
                messageBlockRef.current.scrollTop = messageBlockRef.current.scrollHeight;
            }

            return () => unsubscribe();
        }
    }, [id, userTasks, chatId, db]);

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            sendMessage();
        }
    };
    const sendMessage = () => {
        dispatch(SendMessageAsync({chatId: chatId, taskId: selectedTask?.id, text: text}))
        setText("");
    }

    const registerFunc = (type: TypeChat, name: string) => {
        setNamePrivateChat("");
        setNamePublicChat("");
        dispatch(CreateChatAsync({taskId: id, name, type} as ICreateChat))
    };

    const usersData = [
        {
            id: 1,
            name: 'Admin admin',
            status: 'Exploring',
            statusWhere: 'Library',
        },
        {
            id: 2,
            name: 'Stas sta',
            status: 'Exploring',
            statusWhere: 'Home',
        },
        {
            id: 3,
            name: '10',
            status: 'Exploring',
            statusWhere: 'Chat',
        },
    ];
    return (
        <>
            <Container>
                {id ? <><HeadWrapper>
                    <Text>
                        {selectedTask?.name}
                        <NewMessages>{selectedTask?.chats?.length || "0"}</NewMessages>
                    </Text>
                    <ChatBlockWrapper>
                        {chatId ?
                            <FixedMessage>
                                <AdminInfo>
                                <Avatar src={user?.photoURL || stateUser?.photoURL || DefaultAvatar}></Avatar>
                                    <Names>{selectedTask?.name}</Names>
                                    {/*<Date>{format(parseISO(selectedTask?.created_at as string), 'dd.MM.yyyy, HH:mm')}</Date>*/}
                                </AdminInfo>
                                <CopyIconWrapper src={CopyIcon}/>
                                <DescriptionText>{selectedTask?.message}</DescriptionText>
                                <ReplyButton>Reply</ReplyButton>
                            </FixedMessage> : null}

                    </ChatBlockWrapper>

                </HeadWrapper>

                    <MessageBlock ref={messageBlockRef}>
                        {chatMessages?.map((message, index) => (
                            <Message key={index}>
                                <FixedMessage>
                                    <AdminInfo>
                                        <Avatar src={user?.photoURL || stateUser?.photoURL || DefaultAvatar}></Avatar>
                                        <Names>{message?.owner || "undefined"}</Names>
                                        <Date>{format(parseISO(message?.date as string), 'dd.MM.yyyy, HH:mm')}</Date>
                                    </AdminInfo>
                                    <CopyIconWrapper src={CopyIcon}/>
                                    <DescriptionText>{message?.text || ""}</DescriptionText>
                                </FixedMessage>
                            </Message>
                        ))}
                    </MessageBlock>

                    {chatsOrMembers ? <RightBlockFixed>
                        <InformationBlock>
                            <NameBlock onClick={() => setInformationBlockOpen(!informationBlockOpen)}>
                                <ArrowIconStyled/>
                                Information</NameBlock>
                            {informationBlockOpen && (
                                <ListContainer>
                                    <LabelsWrapper>
                                        <WhiteLabel>Type:</WhiteLabel>
                                        <GrayLabel>{selectedTask?.type}</GrayLabel>
                                    </LabelsWrapper>
                                    <LabelsWrapper>
                                        <WhiteLabel>Level:</WhiteLabel>
                                        <GrayLabel>{selectedTask?.level}</GrayLabel>
                                    </LabelsWrapper>
                                    <LabelsWrapper>
                                        <WhiteLabel>Area:</WhiteLabel>
                                        <GrayLabel>{selectedTask?.area}</GrayLabel>
                                    </LabelsWrapper>
                                    <LabelsWrapper>
                                        <WhiteLabel>Collaboration:</WhiteLabel>
                                        <GrayLabel>{selectedTask?.collaboration}</GrayLabel>
                                    </LabelsWrapper>
                                    <LabelsWrapper>
                                        <WhiteLabel>DeadLine:</WhiteLabel>
                                        <GrayLabel>{selectedTask?.deadline}</GrayLabel>
                                    </LabelsWrapper>
                                    <LabelsWrapper>
                                        <WhiteLabel>Budget:</WhiteLabel>
                                        <GrayLabel>{selectedTask?.budget}</GrayLabel>
                                    </LabelsWrapper>
                                    <LabelsWrapper>
                                        <WhiteLabel>Technologies:</WhiteLabel>
                                        <GrayLabel>{selectedTask?.technologies}</GrayLabel>
                                    </LabelsWrapper>
                                    <LabelsWrapper>
                                        <WhiteLabel>Members:</WhiteLabel>
                                        <GrayLabel>{selectedTask?.members}</GrayLabel>
                                    </LabelsWrapper>
                                    <LabelsWrapper>
                                        <WhiteLabel>Status:</WhiteLabel>
                                        <GrayLabel>{selectedTask?.status}</GrayLabel>
                                    </LabelsWrapper>
                                </ListContainer>)}
                        </InformationBlock>
                        <PublicChatsBlock>
                            <NameBlock onClick={() => setPublicChatsBlockOpen(!publicChatsBlockOpen)}>
                                <ArrowIconStyled/>
                                Public channels
                                <NewPublicMessages>{chatMessages?.length}</NewPublicMessages></NameBlock>
                            {publicChatsBlockOpen && (
                                <>
                                    {Object?.entries(selectedTask?.chats || {})
                                        .filter(([key, chat]) => chat.type === TypeChat.PUBLIC)
                                        .map(([key, chat]) => (
                                            <NameChatBlock key={key}>
                                                <PlanetIconStyled src={PlanetIcon}/>
                                                <NameChatText onClick={() => handleChatClick(key)}>
                                                    {chat?.name}
                                                </NameChatText>
                                            </NameChatBlock>
                                        ))
                                    }

                                    {selectedTask?.ownerId === user?.uid ? (
                                        <CreatePrivateChatButton>
                                            <CreatePrivateChatText value={namePublicChat}
                                                                   onChange={(e) => setNamePublicChat(e.target.value)}
                                                                   type="text" placeholder="Create public chat"/>
                                            <ButtonClick onClick={() => registerFunc(TypeChat.PUBLIC, namePublicChat)}>
                                                <PlusIconStyled/>
                                            </ButtonClick>
                                        </CreatePrivateChatButton>
                                    ) : (<></>)
                                    }
                                </>
                            )}
                        </PublicChatsBlock>
                        <PrivateChatsBlock>
                            <NameBlock onClick={() => setPrivateChatsBlockOpen(!privateChatsBlockOpen)}>
                                <ArrowIconStyled/>
                                Private channels</NameBlock>
                            {privateChatsBlockOpen && (
                                <>
                                    {Object?.entries(selectedTask?.chats || {})
                                        .filter(([key, chat]) => chat.type === TypeChat.PRIVATE)
                                        .map(([key, chat]) => (
                                            <NameChatBlock key={key}>
                                                <PlanetIconStyled src={PlanetIcon}/>
                                                <NameChatText onClick={() => handleChatClick(key)}>
                                                    {chat?.name}
                                                </NameChatText>
                                            </NameChatBlock>
                                        ))
                                    }
                                    {selectedTask?.ownerId === user?.uid ? (
                                        <CreatePrivateChatButton>

                                            <CreatePrivateChatText value={namePrivateChat}
                                                                   onChange={(e) => setNamePrivateChat(e.target.value)}
                                                                   type="text" placeholder="Create private chat"/>
                                            <ButtonClick
                                                onClick={() => registerFunc(TypeChat.PRIVATE, namePrivateChat)}>
                                                <PlusIconStyled/>
                                            </ButtonClick>
                                        </CreatePrivateChatButton>
                                    ) : (<></>)
                                    }
                                </>
                            )}
                        </PrivateChatsBlock>
                    </RightBlockFixed> : <RightBlockFixed>
                        <MembersOnlineBlock>
                            <NameBlock onClick={() => setCurrentlyOnlineBlockOpen(!currentlyOnlineBlockOpen)}>
                                <ArrowIconStyled/>
                                Currently Online</NameBlock>
                            {currentlyOnlineBlockOpen && (
                                <>
                                    {usersArray && usersArray.map((user, index) => (
                                        <UserBlock key={index}>
                                            <UserAvatar src={user.userData.photoURL || DefaultAvatar}/>
                                            <UserStatusBlock>
                                                <UserName>{user.userData.name || "New user"}</UserName>
                                                <UserStatus>
                                                    <UserStatusWhere>{user?.userData.state}</UserStatusWhere>
                                                </UserStatus>
                                            </UserStatusBlock>
                                        </UserBlock>
                                    ))}
                                </>

                            )}
                            <NameBlock onClick={() => setCurrentlyOfflineBlockOpen(!currentlyOfflineBlockOpen)}>
                                <ArrowIconStyled/>
                                Offline</NameBlock>
                            {currentlyOfflineBlockOpen && (
                                <UserOfflineBlock>
                                    {usersData.map((user) => (
                                        <UserBlock key={user.id}>
                                            <UserAvatar src={DefaultAvatar}/>
                                            <UserStatusBlock>
                                                <UserName>{user.name}</UserName>
                                                <UserStatus>
                                                    {user.status}
                                                    <UserStatusWhere>{user.statusWhere}</UserStatusWhere>
                                                </UserStatus>
                                            </UserStatusBlock>
                                        </UserBlock>
                                    ))}
                                </UserOfflineBlock>
                            )}
                        </MembersOnlineBlock>
                    </RightBlockFixed>}

                    <MembersBlock>
                        <BottomGradientBlock>
                            <BottomButtonWrapper>
                                <Button onClick={() => setChatsOrMembers(true)}>Chats</Button>
                                <Button onClick={() => setChatsOrMembers(false)}>Members</Button>
                            </BottomButtonWrapper>
                        </BottomGradientBlock>
                    </MembersBlock>
                    {chatId ?
                        <ChatBlock>
                            <IconStyled src={microphone}/>
                            <InputChat value={text} onChange={(e) => setText(e.target.value)} type="text"
                                       placeholder="You can ask me anything! I am here to help."
                                       $hasText={""} onKeyDown={handleKeyPress}/>
                            <IconStyled style={{position: "absolute", right: "130px"}} src={Screpka}/>
                            <SendMessageButtonClick onClick={sendMessage} src={SendMessageButton}/>
                        </ChatBlock> : null} </> : <Text>Access denied</Text>}
            </Container>
        </>
    );
};

export default ChatComponent;
