import React from "react";

const OkIcon: React.FC = () => {
    return (
        <svg width="19.500000" height="19.500000" viewBox="0 0 19.5 19.5" fill="none" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <desc>
                Created with Pixso.
            </desc>
            <defs/>
            <path id="icon" d="M9.75 18.75C4.7793 18.75 0.75 14.7205 0.75 9.75C0.75 4.77954 4.7793 0.75 9.75 0.75C14.7207 0.75 18.75 4.77954 18.75 9.75C18.75 14.7205 14.7207 18.75 9.75 18.75ZM5.75 9.75L8.04297 12.043C8.43359 12.4333 9.06641 12.4333 9.45703 12.043L13.75 7.75" stroke="#4AC97E" strokeOpacity="1.000000" strokeWidth="1.500000" strokeLinecap="round"/>
        </svg>

);
};

export default OkIcon