import React from "react";
import {
    Container
} from "./styles";
import GlobalMenuBlock from "../MenuBlock/GlobalMenuBlock";
import GlobalTopBlock from "../TopBlock/GlobalTopBlock";
import {Outlet} from "react-router-dom";

const MainPage: React.FC = () => {

    return (
        <Container>
            <GlobalMenuBlock/>
            <GlobalTopBlock/>
            <Outlet/>
        </Container>
    );
};

export default MainPage;
