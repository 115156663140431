import React from "react";

const CodeIcon: React.FC = () => {
    return (
        <svg width="48.000000" height="48.000000" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <path id="icon" d="M4.33594 2.75143L1.04297 6.04434C0.652344 6.43484 0.652344 7.06802 1.04297 7.45853L4.33594 10.7514M14.3359 2.75143L17.6289 6.04434C18.019 6.43484 18.019 7.06802 17.6289 7.45853L14.3359 10.7514M11.3359 0.751434L7.33594 12.7514" stroke="#686B6E" strokeOpacity="1.000000" strokeWidth="1.500000" strokeLinejoin="round"/>
        </svg>

);
};

export default CodeIcon