import React, { useRef, useState } from "react";
import {
  Button, CrossIconWrapper, DialogAboutTask,
  DividerContainer,
  DividerLine,
  DividerText,
  ForgotPasswordText, Highlight,
  InputContainer, List, ListItem, Overlay,
  RememberText,
  SmallText,
  SocialLoginButton,
  Text,
  TextGradient,
  ToggleRememberContainer
} from "./styles";
import { Container } from "../../style/GlobalStyle";
import DangerComponent, { DangerMessageType } from "../../style/DangerComponent";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { useAppDispatch } from "../../store/hooks";
import { IUserLogin } from "../../store/types";
import { loginFacebookUserAsync, loginGoogleUserAsync, RegisterUserAsync } from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { AboutRegister, ErrorBlock, SubtitleError } from "../LogInForm/styles";
import CircleIcon from "../../assets/CircleIcon";

const RegisterForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const error = useSelector((state: RootState) => state.app.error);
  const loading = useSelector((state: RootState) => state.app.loading);
  const [showNotification, setShowNotification] = useState(false);
  const [remember, setRemember] = useState(false);
  const formikRef = useRef<FormikProps<IUserLogin>>(null);
  const [showAbout, setShowAbout] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Incorrect email entered*")
      .required("Login is obligatory field"),
    password: Yup.string()
      .required("Incorrect password entered*")
      .min(6, "Password is too short - should be 6 chars minimum.")
  });
  const registerCredentials: IUserLogin = {
    email: "",
    password: ""
  };

  const closeNotificationHandler = () => {
    setShowNotification(false);
  };

  const registerFunc = (values: IUserLogin) => {
    dispatch(RegisterUserAsync(values))
      .then((result: any) => {
        if (result.meta.requestStatus === "fulfilled") {
          navigate(routes.HOME);
          return;
        } else {
          switch (result.payload.code) {
          case "auth/invalid-email":
            setShowNotification(true);
            break;
          case "auth/user-disabled":
            setShowNotification(true);
            break;
          case "auth/user-not-found":
            setShowNotification(true);
            break;
          case "auth/wrong-password":
            setShowNotification(true);
            break;
          case "auth/invalid-credential":
            setShowNotification(true);
            break;
          case "auth/email-already-in-use":
            setShowNotification(true);
            break;
          default:
            console.log("An unknown error occurred:", result.payload.code);
          }
        }
      });
  };

  const googleLogin = () => {
    dispatch(loginGoogleUserAsync(formikRef.current!.values))
      .then((result: any) => {
        if (result.meta.requestStatus === "fulfilled") {
          navigate(routes.HOME);
          return;
        } else {
          switch (result.payload.code) {
          case "auth/invalid-email":
            setShowNotification(true);
            break;
          case "auth/user-disabled":
            setShowNotification(true);
            break;
          case "auth/user-not-found":
            setShowNotification(true);
            break;
          case "auth/wrong-password":
            setShowNotification(true);
            break;
          case "auth/invalid-credential":
            setShowNotification(true);
            break;
          default:
            console.log("An unknown error occurred:", result.payload.code);
          }
        }
      });
  };
  const facebookLogin = () => {
    dispatch(loginFacebookUserAsync(formikRef.current!.values))
      .then((result: any) => {
        if (result.meta.requestStatus === "fulfilled") {
          navigate(routes.HOME);
          return;
        } else {
          switch (result.payload.code) {
          case "auth/invalid-email":
            setShowNotification(true);
            break;
          case "auth/user-disabled":
            setShowNotification(true);
            break;
          case "auth/user-not-found":
            setShowNotification(true);
            break;
          case "auth/wrong-password":
            setShowNotification(true);
            break;
          case "auth/invalid-credential":
            setShowNotification(true);
            break;
          default:
            console.log("An unknown error occurred:", result.payload.code);
          }
        }
      });
  };

  const clickRemember = () => setRemember(!remember);
  const handleClickAbout = () => {
    setShowAbout(true);
  };
  return (
    <Container>
      {showNotification ?
        <DangerComponent
          show={showNotification}
          close={closeNotificationHandler}
          error={error}
          color="red"
          icon={<span>⚠️</span>}
          type={DangerMessageType.Error}
        /> : null}
      <Text>Hello<TextGradient>Developer!</TextGradient></Text>


      <Formik initialValues={registerCredentials} validationSchema={validationSchema} onSubmit={registerFunc}
              innerRef={formikRef}>
        {({ errors, touched }) => (
          <Form style={{ width: "100%" }}>
            <ErrorBlock>
              <ErrorMessage name="email"
                            component="p"
                            className="error-message" />
              <ErrorMessage name="password"
                            component="p"
                            className="error-message" />
              {error && <SubtitleError>{error.code}</SubtitleError>}
            </ErrorBlock>
            <InputContainer $error={!!errors.email && touched.email}>
              <Field type="email" name="email" placeholder="Email" />
            </InputContainer>
            <InputContainer $error={!!errors.password && touched.password}>
              <Field type="password" name="password" placeholder="Password" />
            </InputContainer>
            <Button type="submit" disabled={loading}>
              {loading ? "Registration..." : "Register"}
            </Button>
          </Form>
        )}
      </Formik>
      <AboutRegister onClick={handleClickAbout}>
        About registration
      </AboutRegister>
      {/*<DividerContainer>*/}
      {/*    <DividerLine/>*/}
      {/*    <DividerText>or continue with e-mail</DividerText>*/}
      {/*    <DividerLine/>*/}
      {/*</DividerContainer>*/}
      {/*<ContinueContainer>*/}
      {/*    <SocialLoginButton onClick={googleLogin}><GoogleIcon/>Google Account</SocialLoginButton>*/}
      {/*    <SocialLoginButton onClick={facebookLogin}><AppleIcon/>Facebook Account</SocialLoginButton>*/}
      {/*</ContinueContainer>*/}
      {/*<ToggleRememberContainer>*/}
      {/*    <ToggleRemember*/}
      {/*        id="remember"*/}
      {/*        name="remember"*/}
      {/*        type="checkbox"*/}
      {/*        onClick={clickRemember}*/}
      {/*        $isChecked={remember}*/}
      {/*    > <RememberText>Remember me</RememberText>*/}
      {/*    </ToggleRemember>*/}
      {/*    <ForgotPasswordText onClick={() => navigate(routes.FORGOTPASSWORD)}>*/}
      {/*        Forgo password?*/}
      {/*    </ForgotPasswordText>*/}
      {/*</ToggleRememberContainer>*/}
      <SmallText> Have an account?
        <TextGradient onClick={() => navigate(routes.LOGIN)}
                      style={{ cursor: "pointer" }}>
          Login
        </TextGradient>
      </SmallText>
      {showAbout ? <Overlay>

<DialogAboutTask>
  <CrossIconWrapper>
    <CircleIcon onClick={() => setShowAbout(false)} />
  </CrossIconWrapper>
  <List>
    <ListItem>
      <Highlight>Registration on the CodeMinds platform:</Highlight> gives users access to a variety of opportunities that will help them develop and find solutions to various problems. During registration:
    </ListItem>
    <ListItem>
      <Highlight>Free account:</Highlight> All new users receive a free account, which provides access to view all tasks posted on the platform.
    </ListItem>
    <ListItem>
      <Highlight>Communicate in public chats:</Highlight> Users can participate in discussions and share knowledge in public chats, which promotes community development and cooperation between participants.
    </ListItem>
    <ListItem>
      <Highlight>Apply for participation:</Highlight> To apply for the task, users must change their status to a minimum of "Newbie". This helps ensure that only serious and proven developers participate in projects.
    </ListItem>
  </List>

</DialogAboutTask>
        </Overlay> : null}
    </Container>
  );
};

export default RegisterForm;
