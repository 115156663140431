import React from "react";
import {
  Container, Letter, LogoContainer,
  Text
} from "./styles";
import CodeMindsLogo from "../../assets/CodeMindsLogo";


const OverlayLoading: React.FC = () => {
  const letters = ' loading...'.split('');

    return (
      <Container>
        <Text>
          <LogoContainer>
            <CodeMindsLogo/>
          </LogoContainer>
          {letters.map((letter, index) => (
            <Letter key={index} delay={`${index * 0.1}s`}>
              {letter}
            </Letter>
          ))}
        </Text>
      </Container>
    );
};

export default OverlayLoading;
