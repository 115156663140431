import React from "react";
import {
    Text,
} from "./styles";


const NotFound: React.FC = () => {

    return (
        <div>
            <Text>Page not found</Text>
        </div>
    );
};

export default NotFound;
