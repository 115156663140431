import React from "react";

const ShareIcon: React.FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    >
            <path id="icon" d="M0.75 8.25C0.75 6.86929 1.86938 5.75 3.25 5.75C4.16016 5.75 4.95679 6.23645 5.39404 6.96357C5.62012 7.3394 5.75 7.77952 5.75 8.25C5.75 8.72048 5.62012 9.1606 5.39404 9.53643C4.95679 10.2635 4.16016 10.75 3.25 10.75C1.86938 10.75 0.75 9.63071 0.75 8.25ZM11.5833 5.75C10.6731 5.75 9.87646 5.26355 9.43921 4.53643C9.21338 4.1606 9.08325 3.72048 9.08325 3.25C9.08325 1.86929 10.2026 0.75 11.5833 0.75C12.9641 0.75 14.0833 1.86929 14.0833 3.25C14.0833 4.63071 12.9641 5.75 11.5833 5.75ZM11.5833 15.75C10.2026 15.75 9.08325 14.6307 9.08325 13.25C9.08325 12.7795 9.21338 12.3394 9.43921 11.9636C9.87646 11.2365 10.6731 10.75 11.5833 10.75C12.9641 10.75 14.0833 11.8693 14.0833 13.25C14.0833 14.6307 12.9641 15.75 11.5833 15.75ZM5.39404 9.53643L9.43921 11.9636M9.43921 4.53643L5.39404 6.96357" stroke="#686B6E" strokeOpacity="1.000000" strokeWidth="1.500000" strokeLinejoin="round"/>
        </svg>
);
};

export default ShareIcon