import styled, {createGlobalStyle, keyframes} from "styled-components";
import CircleIcon from "../assets/CircleIcon";
import AlertIcon from "../assets/AlertIcon";

interface StyledInputProps {
    hasText?: string;
    error?: boolean;
    warning?: boolean;
}

const gradientAnimation = keyframes`
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
`;
export const Container = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-box-align: stretch;
    min-height: 768px;
    width: calc(100% - 2rem);
    height: auto;
    max-width: 478px;
    max-height: 90%;
    border-radius: 10px;
    background: linear-gradient(225.00deg, rgb(10, 30, 30), rgb(14, 48, 48), rgb(18, 21, 37), rgb(32, 55, 58), rgb(182, 240, 156));
    background-size: 300% 300%;
    animation: ${gradientAnimation} 20s ease infinite;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    padding: 20px;
    @media (max-width: 768px) {
        min-width: 50%;
        max-width: 90%;
        width: 60%;
        height: auto;
        min-height: 50%;
        max-height: 80%;
        border-radius: 30px;
        // background: linear-gradient(225.00deg, rgb(68, 206, 202), rgb(37, 128, 128), rgb(70, 81, 145), rgb(135, 221, 238), rgb(182, 240, 156));
        // background-size: 300% 300%;
            // animation: ${gradientAnimation} 20s ease infinite;
    }
`;

export const Image = styled.img`
    position: absolute;
    max-width: 40%;
    height: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 100px;
    @media (max-width: 768px) {
        display: none;
    };
`;
export const Text = styled.div`
    position: relative;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    color: aliceblue;
    @media (max-width: 768px) {
        font-size: 24px;
    };
`;
export const ContinueContainer = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    z-index: -100;
    @media (max-width: 768px) {
        padding: 20px;
    };
`;

export const GlobalStyle = createGlobalStyle`

    @font-face {
        font-weight: 700;
    }

    body {
        margin: 0;
        padding: 0;
        background-color: rgb(0, 0, 0);
    }

    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    *:hover::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
`;

export const Input = styled.input<StyledInputProps>`
    position: relative;
    width: 100%;
    height: 48px;
    border-radius: 8px;
    background: rgb(26, 29, 33);
    color: white !important;
    transition: border-color 0.3s ease;
    outline: none;
    box-sizing: border-box;
    border: ${(props) => {
        if (props.error) {
            return '1px solid rgb(208, 48, 47)';
        }
        if (!props.warning && props.hasText) {
            return '1px solid rgb(226, 111, 32)';
        } else {
            return '1px solid rgb(54, 58, 61)';
        }
    }};
    margin-right: 10px;
    padding: 0 20px;

    &:not(:placeholder-shown) {
        border-color: ${(props) => (props.hasText && props.warning ? 'green' : '1px solid rgb(226, 111, 32)')};
    }

    &:before {
        content: '';
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        border: 1px solid transparent;
        border-radius: 8px;
        transition: border-color 0.3s ease;
        box-sizing: border-box;
    }

    &:focus {
        border-color: rgb(130, 219, 247);
        box-shadow: 0 0 0 1px rgb(182, 240, 156), 0 0 5px 4px rgba(132, 220, 245, 0.24);
    }

    &:focus::before {
        border-color: rgba(132, 220, 245, 0.24);
    }

    @media (max-width: 768px) {
        padding-left: 45px;
    }
`;
export const ToggleRemember = styled.div<{ $isChecked?: boolean, name?: string, type?: string }>`
    height: 10px;
    width: 10px;
    position: relative;
    background: ${(props) =>
            props.$isChecked
                    ? "linear-gradient(45.00deg, rgb(77, 98, 229) 0%,rgb(135, 221, 238) 45.313%,rgb(182, 240, 156) 100%)"
                    : "rgb(26, 29, 33)"};
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: ${(props) =>
            props.$isChecked
                    ? "0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px -1px rgba(0, 0, 0, 0.05)"
                    : "none"};
    border: 1px solid ${(props) => (props.$isChecked ? "rgb(182, 240, 156)" : "rgb(54, 58, 61)")};
    border-radius: 4px;

    &:hover {
        border-color: ${(props) => (props.$isChecked ? "rgb(135, 221, 238)" : "rgb(182, 240, 156)")};
    }

    &::after {
        content: "${(props) => (props.$isChecked ? "\u2714" : "")}";
        font-size: 18px;
        font-weight: bold;
        color: #000;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: ${(props) => (props.$isChecked ? "1" : "0")};
        transition: opacity 0.2s ease;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

export const Danger = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    width: 382px;
    height: 84px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 8px 10px -6px rgba(0, 0, 0, 0.05), 0px 20px 25px -5px rgba(0, 0, 0, 0.05);
    background: rgb(6, 7, 8);
    color: antiquewhite;
    padding: 16px;
    z-index: 100;
`;

export const DangerContent = styled.div`
    display: flex;
    align-items: center;
`;

export const DangerCloseIcon = styled(CircleIcon)`
    width: 24px;
    height: 24px;
`;

export const ErrorIconStyled = styled(AlertIcon)`
    width: 24px;
    height: 24px;
`;
export const AdditionalText = styled.div`
    position: relative;
    left: 70px;
    top: -34px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.15px;
    text-align: left;
`;

export const NotificationWrapper = styled.div`
    position: fixed;
    top: 20px;
    right: 50px;
    width: 200px;
    height: 48px;
    padding: 20px;
    background-color: rgb(26, 29, 33);
    border: none;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease-in-out;
    z-index: 1;
    @media (max-width: 768px) {
        right: 0;
    }
`;

export const NotificationContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
    white-space: nowrap;
`;

export const IconWrapper = styled.div`
    margin-right: 10px;
`;
export const AlertMessage = styled.div`
    font-size: 14px;
    position: relative;
    width: 150px;
    color: red;
    background: white;
`;
export const AlertIconWrapper = styled.div`
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 10px;
    width: 24px;
    height: 24px;

    &:hover + ${AlertMessage} {
        display: block; /* Показать сообщение при наведении на alert-icon-wrapper */
    }
`;

export const DangerTitle = styled.div`
    font-weight: bold;
    color: rgb(253, 86, 2);
`;

export const DangerText = styled.div`
    color: white;
`;

export const CloseIconWrapper = styled.div`
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
`;

export const InputForm = styled.div`
    position: relative;
    width: 600px;
    margin-left: 35px;
    @media (max-width: 1600px) {
        margin-left: -20px;
    }
    @media (max-width: 768px) {
        margin-left: -30px;
    }
`;

