import React, { useEffect } from "react";
import {
  CloseIconWrapper,
  DangerText,
  DangerTitle, IconWrapper,
  NotificationContent, NotificationWrapper
} from "./GlobalStyle";
import { IError } from "../store/types";
import { CSSTransition } from "react-transition-group";


interface StyledDangerProps {
  show: boolean;
  close: () => void;
  icon?: React.ReactNode;
  error?: IError | null;
  color?: string;
  type?: DangerMessageType;
  message?: string;
}

export enum DangerMessageType {
  Warning = "Warning",
  Error = "Error",
  Ok = "Ok",
  Ask = "Ask",
}

const DangerComponent: React.FC<StyledDangerProps> = ({ show, close, icon, error, color, message }) => {
  const cleanedMessage = error?.message.replace("Firebase: Error ", "");

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        close();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [show, close, error, message]);

  return (
    <CSSTransition
      in={show}
      timeout={300}
      classNames="fade"
      unmountOnExit
    >
      <NotificationWrapper>
        <NotificationContent>
          <>
            <IconWrapper>{icon}</IconWrapper>
            <DangerTitle>{cleanedMessage}</DangerTitle>
            <DangerText style={{ color: color }}>{message}</DangerText>
          </>
        </NotificationContent>
        <CloseIconWrapper onClick={close}>
          <div style={{ color: "white" }}>✖</div>
        </CloseIconWrapper>
      </NotificationWrapper>
    </CSSTransition>
  );
};

export default DangerComponent;
