import styled from "styled-components";
import {StyledInputProps} from "../../store/types";
import PlusIconSvg from "../../assets/PlusIconSvg";
export const Container = styled.div`
  position: absolute;
  justify-content: right;
  top: 195px;
  left: 340px;
  right: 10px;
  bottom: 10px;
  min-width: 70%;
  box-sizing: border-box;
  border-bottom: 1px solid rgb(9, 32, 39);
  box-shadow: 0 8px 10px -6px rgba(6, 7, 8, 0.06), 0px 25px 50px -12px rgba(6, 7, 8, 0.16);
`;

export const Text = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    text-align: center;
    left: 20px;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    color: aliceblue;
`;
export const HeadWrapper = styled.div`
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    height: 53px;
    left: 340px;
    right: 370px;
    padding: 24px 0 22px 20px;
    box-sizing: border-box;
`;

export const NewMessages = styled.div`
    width: 33px;
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 12px;
    color: rgb(182, 240, 156);
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.15px;
    margin-left: 30px;
    text-align: center;
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 12px;
    background: linear-gradient(145.32deg, rgba(215, 237, 237, 0.16) -60.906%, rgba(204, 235, 235, 0) 100%);
`;
export const NewPublicMessages = styled.div`
    position: fixed;
    right: 30px;
    width: 33px;
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: rgb(182, 240, 156);
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.15px;
    text-align: center;
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 12px;
    background: linear-gradient(145.32deg, rgba(215, 237, 237, 0.16) -60.906%, rgba(204, 235, 235, 0) 100%);
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    text-align: right;
    height: 50px;
    right: 400px;
    box-sizing: border-box;
    border-radius: 20px;
`;
export const ImageStyled = styled.img`
    height: 15px;
    width: 10px;
    position: relative;
    margin-left: 40px;
    cursor: pointer;
    margin-right: 20px;
    &:hover {
        opacity: 0.5;
    }
`;
export const CopyIconWrapper = styled.img`
    right: 30px;
    height: 15px;
    width: 15px;
    position: absolute;
    cursor: pointer;

`;

export const ChatBlockWrapper = styled.div`
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    height: auto;
    top: 250px;
    left: 340px;
    right: 370px;
    border-radius: 20px;
    padding: 10px;
    background: linear-gradient(145.32deg, rgba(215, 237, 237, 0.16) -60.906%, rgba(204, 235, 235, 0) 100%);
    z-index: 100;
`;

export const FixedMessage = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    width: 100%;
    border-radius: 16px;
    box-shadow: 0 8px 10px -6px rgba(6, 7, 8, 0.06), 0px 25px 50px -12px rgba(6, 7, 8, 0.07);
`;
export const Avatar = styled.img`
    width: 48px;
    height: 48px;
    border-radius: 50%;
`;
export const AdminInfo = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: auto;
    gap: 10px;
`;

export const Names = styled.div`
    position: relative;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-left: 5px;
`;
export const Date = styled.div`
    position: relative;
    color: rgb(104, 107, 110);
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.15px;
    margin-left: 10px;
`;
export const DescriptionText = styled.div`
    position: relative;
    max-width: 80%;
    height: auto;
    color: rgb(155, 156, 158);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    word-wrap: break-word; 
    overflow-wrap: break-word;
    word-break: break-word;
    margin-left: 60px;
    padding-bottom: 20px;
`;
export const ReplyButton = styled.button`
    position: absolute;
    width: 58px;
    height: 32px;
    border: none;
    border-radius: 8px;
    background: rgb(26, 29, 33);
    color: rgb(155, 156, 158);
    cursor: pointer;
    bottom: -20px;
    left: 50px;
`;
export const MembersBlock = styled.div`
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 350px;
`;
export const BottomGradientBlock = styled.div`
    position: static;
    width: 100%;
    height: 150px;
    background: linear-gradient(180.00deg, rgba(19, 22, 25, 0) -7%,rgb(19, 22, 25) 73.333%);
    border-radius: 0 0 20px 20px;
`;

export const BottomButtonWrapper = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
`;

export const Button    = styled.button`
    width: 50%;
    height: 50px;
    border-radius: 12px;
    background: rgb(14, 23, 27);
    color: rgb(155, 156, 158);
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid rgb(26, 29, 33);

    &:hover {
        background-color: rgb(54, 58, 61);
        color: rgb(182, 240, 156);
    }

    &:active {
        transform: scale(0.98);
        background-color: rgb(104, 107, 110);
        color: rgb(205, 206, 207);
    }
`;

export const InformationBlock = styled.div`
    position: relative;
    margin-top: 20px;
`;
export const PublicChatsBlock = styled.div`
    position: relative;
    margin-top: 20px;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
`;
export const PrivateChatsBlock = styled.div`
    position: relative;
    margin-top: 20px;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
`;
export const MembersOnlineBlock = styled.div`
    position: relative;
    margin-top: 20px;
`;

export const NameBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;
    color: rgb(155, 156, 158);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.15px;
    cursor: pointer;
    margin-top: 20px;

`;
export const NameChatBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    margin: 10px;
`;

export const NameChatText = styled.div`
    color: rgb(155, 156, 158);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.15px;
    margin-left: 10px;
    cursor: pointer;
    &:hover {
        color: rgb(182, 240, 156);
    }
    &:active{
        transform: scale(0.98);
        color: rgb(205, 206, 207);
    }
`;
export const PlanetIconStyled = styled.img`
    width: 24px;
    height: 24px;
`;

export const CreatePrivateChatText = styled.input`
    margin: 0 8px;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    color: rgb(155, 156, 158);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.15px;
    &:focus {
        outline: none;
    }
`;
export const ButtonClick = styled.div`
    cursor: pointer;
`;
export const CreatePrivateChatButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    text-align: left;
    margin-top: 30px;
`;
export const PlusIconStyled = styled(PlusIconSvg)`
    width: 24px;
    height: 24px;
`;

export const ListContainer = styled.div`
    position: static;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    flex: none;
    order: 1;
    flex-grow: 0;
`;

export const LabelsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-style: solid;
    border-width: 0 0 1px 0;
    border-image-source: linear-gradient(225.00deg, rgba(48, 69, 201, 0.5), rgba(101, 190, 218, 0.5) 45.313%, rgba(154, 211, 127, 0.5) 100%);
    border-image-slice: 1;
`;

export const WhiteLabel = styled.div`
    margin: 0 40px;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: center;
`;

export const GrayLabel = styled.div`
    margin: 0 10px;
    color: rgb(155, 156, 158);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: center;
`;
export const RightBlockFixed = styled.div`
    position: fixed;
    right: 10px;
    top: 190px;
    width: 350px;
`;

export const UserBlock = styled.div`
    width: 264px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 0;
    margin: 24px 0;
`;
export const UserOfflineBlock = styled.div`

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
`;
export const UserAvatar = styled.img`
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 50%;
`;
export const UserName = styled.div`
    position: relative;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin: 0;
`;
export const UserFamily = styled.div`
    position: relative;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin: 0;
    padding-left: 5px;
`;

export const UserStatusBlock = styled.div`
    display: flex;
    justify-content: left;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
`;
export const UserStatus = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.15px;
    text-align: center;
    color: rgb(155, 156, 158);
`;
export const UserStatusWhere = styled.div`
    position: relative;
    margin: 4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.15px;
    color: rgb(77, 98, 229)
`;
export const UserExploringBlock = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
`;

export const MessageBlock = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    top:380px;
    left: 345px;
    right: 350px;
    bottom: 100px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    gap: 20px;
`;


export const Message = styled.div`
    position: relative;
    height: auto;
`;
export const ChatBlock = styled.div`
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    height: 93px;
    left: 340px;
    right: 370px;
    bottom: 10px;
    padding: 24px 0 22px 20px;
    box-sizing: border-box;
    border-radius: 20px;
    background: rgb(14, 23, 27);
`;
export const IconStyled = styled.img`
    position: relative;
    height: 24px;
    width: 24px;
    cursor: pointer;
    &:active {
        opacity: 0.8;
    }
`;
export const InputChat = styled.input<StyledInputProps>`
    position: absolute;
    left: 60px;
    height: 48px;
    max-width: 100%;
    min-width: 80%;
    border-radius: 8px;
    background: rgb(14, 23, 27);
    color: rgb(255, 255, 255);
    transition: border-color 0.3s ease;
    outline: none;
    padding-left: 10px;
    border: ${(props) => {
    if (props.$error) {
        return '1px solid rgb(208, 48, 47)';
    }
    if (!props.$warning && props.$hasText) {
        return '1px solid rgb(226, 111, 32)';
    } else {
        return 'none';
    }
}};

    &:not(:placeholder-shown) {
        border-color: ${(props) => (props.$hasText && props.$warning ? 'green' : '1px solid rgb(226, 111, 32)')};
    }

    &:before {
        content: '';
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        border: 1px solid transparent;
        border-radius: 8px;
        transition: border-color 0.3s ease;
        box-sizing: border-box;
    }

    &:focus {
        border-color: rgb(130, 219, 247);
        box-shadow: 0 0 0 1px rgb(182, 240, 156), 0 0 5px 4px rgba(132, 220, 245, 0.24);
    }

    &:focus::before {
        border-color: rgba(132, 220, 245, 0.24);
    }

    @media (max-width: 768px) {
        padding-left: 45px;
    }
`;
export const SendMessageButtonClick = styled.img`
    position: relative;
    width: 48px;
    height: 48px;
    padding: 8px 24px 8px 24px;
    margin: 0 24px;
    cursor: pointer;
    &:active {
        opacity: 0.8;
    }
`;
