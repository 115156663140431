import React, { useEffect } from "react";
import {
  Block,
  MenuBlock,
  MenuItem,
  ShareBlock,
  SmallTextBlock,
  ShareBar,
  TextBlock,
  TextMenu,
  UsersOnlineBlock,
  AvatarsOnline, StatusOnline, AvatarOnline, MenuImage, AvatarOnlineCount
} from "./styles";
import ShareIcon from "../../assets/ShareIcon";
import {
  ChatIcon,
  HomeIcon,
  LibraryIcon,
  StatusOnlineIcon,
  TasksIcon
} from "../../assets";
import { useAppDispatch } from "../../store/hooks";
import { ref, onValue, getDatabase, set, onDisconnect } from "firebase/database";
import { setData, setOnlineUsers, setUserTasks } from "../../store/slices/userSlice";
import { getAuth } from "firebase/auth";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Task } from "../../store/types";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";

const GlobalTopBlock: React.FC = () => {
  const onlineUsers = useSelector((state: RootState) => state.app?.onlineUsers);
  const auth = getAuth();
  const user = auth.currentUser;
  const dispatch = useAppDispatch();
  const db = getDatabase();
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    if (user?.uid) {
      const userDataRef = ref(db, "users/" + user?.uid);
      const taskRef = ref(db, "tasks/");
      const userStatusRef = ref(db, "/status/" + user?.uid);
      const isOnlineRef = ref(db, ".info/connected");

      const unsubscribeUserData = onValue(userDataRef, (snapshot) => {
        const userData = snapshot.val();
        dispatch(setData(userData));
      });

      const unsubscribeTasks = onValue(taskRef, (snapshot) => {
        const taskData: Record<string, Task> | null = snapshot.val();
        const tasksWithKeys: Task[] = taskData
          ? Object.entries(taskData)
            .map(([key, value]): Task => ({
              ...value,
              id: key
            }))
            .filter((task: Task) =>
              task.ownerId === user?.uid ||
              task.invitedMembers?.some(member => member.id === user?.uid)
            ) : [];
        dispatch(setUserTasks(tasksWithKeys));
      });

      // const unsubscribeTasks = onValue(taskRef, (snapshot) => {
      //     const taskData = snapshot.val();
      //     dispatch(setUserTasks(taskData));
      // });

      onValue(isOnlineRef, (snapshot) => {
        if (snapshot.val() === true) {
          if (user?.displayName && user?.photoURL) {
            set(userStatusRef, {
              name: user.displayName,
              state: "online",
              last_changed: new Date().toISOString(),
              photoURL: user.photoURL
            });

            onDisconnect(userStatusRef).set({
              state: "offline",
              last_changed: new Date().toISOString()
            });
          } else {
            console.error("User data is incomplete:", user);
          }
        }
      });

      const onlineUsersRef = ref(db, "/status");
      const unsubscribeOnlineUsers = onValue(onlineUsersRef, (snapshot) => {
        if (snapshot.exists()) {
          const usersStatus = snapshot.val();
          const onlineUsersList = Object.entries(usersStatus as {
            [key: string]: { state: string; photoURL?: string; }
          })
            .filter(([userId, userData]) => userData.state === "online")
            .map(([userId, userData]) => ({
              userId,
              userData
            }));
          dispatch(setOnlineUsers(onlineUsersList));
        } else {
          dispatch(setOnlineUsers([]));
        }
      });

      return () => {
        unsubscribeUserData();
        unsubscribeTasks();
        unsubscribeOnlineUsers();
      };
    }
  }, [user, db, dispatch, navigate]);


  // useEffect(() => {
  //     let client: CompatClient;
  //
  //     const connectWebSocket = () => {
  //         const socket = new SockJS(`${base_url}/ws`);
  //         client = Stomp.over(socket);
  //         const headers = {
  //             Authorization: `Bearer ${user.token}`,
  //             Email: user.email,
  //         };
  //         client.connect(headers, {userId: user.id}, (frame: Frame) => {
  //             if (client.connected) {
  //                 console.log('WebSocket Connection Established');
  //                 window.addEventListener('beforeunload', () => {
  //                     client.disconnect();
  //                 });
  //                 client.send("/app/validateToken", headers);
  //                 client.subscribe('/topic/validateToken', (response) => {
  //                     const newToken = response.body;
  //                     dispatch(updateToken(newToken));
  //                 });
  //                 client.subscribe('/topic/onlineUsersCount', (response) => {
  //                     const onlineUsersCount = JSON.parse(response.body);
  //                     setOnlineUsersCount(onlineUsersCount);
  //                 })
  //             } else {
  //                 console.error('WebSocket Connection Failed');
  //             }
  //         });
  //     };
  //     connectWebSocket();
  //     const interval = setInterval(() => {
  //         connectWebSocket();
  //     }, 15 * 60 * 1000);
  //
  //     return () => {
  //         clearInterval(interval);
  //         if (client) {
  //             client.disconnect();
  //         }
  //     };
  // }, [user.id, user.token]);

  return (
    <>
      <Block>
        <TextBlock>CodeMinds</TextBlock>
        <SmallTextBlock>CodeMinds: Unleashing Innovation, One Line of Code at a Time!</SmallTextBlock>
        <UsersOnlineBlock>
          {onlineUsers?.map((user, index) => (
            <AvatarsOnline key={index}>
              <AvatarOnline src={user.userData.photoURL} />
              <StatusOnline src={StatusOnlineIcon} />
            </AvatarsOnline>
          ))}
          {onlineUsers && (
            <AvatarsOnline>
              <AvatarOnlineCount $count={onlineUsers?.length} />
            </AvatarsOnline>
          )}
        </UsersOnlineBlock>
        <ShareBar>
          <ShareIcon />
          <ShareBlock>
            Share
          </ShareBlock>
        </ShareBar>
      </Block>
      <MenuBlock>
        <MenuItem $link={location.pathname === "/home"}>
          <MenuImage src={HomeIcon} />
          <TextMenu>Home</TextMenu>
        </MenuItem>
        <MenuItem $link={location.pathname.startsWith("/home/chat/")}>
          <MenuImage src={ChatIcon} />
          <TextMenu className="child-element">Chat</TextMenu>
        </MenuItem>
        <MenuItem $link={location.pathname === "/library"}>
          <MenuImage src={LibraryIcon} />
          <TextMenu className="child-element">Library</TextMenu>
        </MenuItem>
        <MenuItem $link={location.pathname.startsWith("/home/tasks/")}>
          <MenuImage src={TasksIcon} />
          <TextMenu className="child-element">Tasks</TextMenu>
        </MenuItem>
      </MenuBlock>
    </>
  );
};

export default GlobalTopBlock;
