import {Route, Routes} from "react-router-dom";
import LoginForm from "./components/LogInForm/LoginForm";
import {routes} from "./routes";
import RegisterForm from "./components/RegisterForm/RegisterForm";
import MainPage from "./components/MainPage/MainPage";
import NotFound from "./components/NotFound/NotFound";
import CreateTaskBlock from "./components/CreateTaskBlock/CreateTaskBlock";
import TasksBlock from "./components/TasksBlock/TasksBlock";
import LibraryBlock from "./components/LibraryBlock/LibraryBlock";
import ChatComponent from "./components/ChatBlock/ChatComponent";
import BillingComponent from "./components/BillingComponent/BillingComponent";
import PersonalSettingsBlock from "./components/PersonalSettingsBlock/PersonalSettingsBlock";
import Assistants from "./components/AssistantsBlock/Assistants";


export const AppRouter = () => {
    return (
        <Routes>
            <Route index element={<LoginForm/>}/>
            <Route path={routes.REGISTER} element={<RegisterForm/>}/>
            <Route path={routes.LOGIN} element={<LoginForm/>}/>
            <Route path={routes.NOT_FOUND} element={<NotFound />} />
            <Route path={routes.HOME} element={<MainPage />}>
                <Route path={"/home/create"} element={<CreateTaskBlock />} />
                <Route path={"/home/tasks"} element={<TasksBlock />} />
                <Route path={"/home/library"} element={<LibraryBlock />} />
                <Route path={"/home/chat/:id/:chatId"} element={<ChatComponent />} />
                <Route path={"/home/billing"} element={<BillingComponent />} />
                <Route path={"/home/settings"} element={<PersonalSettingsBlock />} />
                <Route path={"/home/assistants"} element={<Assistants />} />
            </Route>
            <Route path={routes.TASKS} element={<MainPage />}>
                <Route path={"/home/tasks/web"} element={<TasksBlock />} />
                <Route path={"/home/tasks/problems"} element={<TasksBlock />} />
                <Route path={"/home/tasks/mobile"} element={<TasksBlock />} />
                <Route path={"/home/tasks/startups"} element={<TasksBlock />} />
            </Route>
        </Routes>
    );
};
