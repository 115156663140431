export interface StyledInputProps {
  $hasText?: string;
  $error?: boolean;
  $warning?: boolean;
}

export interface OpenDropdownProps {
  isActive?: boolean;
}

export interface AuthState {
  loading: boolean;
  user: null | INewUser;
  data: null | IData;
  error: IError | null;
  onlineUsers: null | OnlineUsers[];
  tasks: null | Task[];
}

export interface OnlineUsers {
  userId: string;
  userData: {
    name: string;
    last_changed: Date;
    photoURL: string;
    state: string;
  };
}

export interface INewUser {
  displayName?: string | null;
  emailVerified?: boolean;
  isAnonymous?: boolean;
  metadata?: {
    creationTime?: string;
    lastSignInTime?: string;
  };
  photoURL?: string | null;
  providerId?: string;
  tenantId?: string | null;
  uid?: string;
  stsTokenManager?: {
    accessToken?: string;
    expirationTime?: number;
    refreshToken?: string;
  };
}

export interface IData {
  balance?: number;
  endDate?: string;
  status?: string;
  assistentChats?: Chat[];
}

export interface IAuthResponse {
  user?: INewUser;
  data?: IData;
}

interface Skill {
  id: number;
  name: string;
}

interface Link {
  id: number;
  name: string;
  url: string;
}

export enum TypeChat {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE"
}

export interface IMessage {
  messageId: string,
  owner?: string;
  date?: string;
  text?: string;
}

export interface Chat {
  id?: string;
  name?: string;
  type?: TypeChat;
  members?: string[];
  messages?: IMessage[];
}

export enum TypeTask {
  WEB_APPLICATION = "WEB_APPLICATION",
  PROBLEM_SOLVING = "PROBLEM_SOLVING",
  MOBILE_APP = "MOBILE_APP",
  STARTUPS = "STARTUPS"
}


export interface Task {
  id?: string;
  ownerId?: string;
  name?: string;
  type?: TypeTask;
  level?: number;
  area?: string;
  collaboration?: string;
  deadline?: string;
  budget?: number;
  technologies?: string[];
  members?: number;
  message?: string;
  chats?: Chat[];
  created_at?: string;
  status: TaskStatus;
  invitedMembers?: InvitedMember[];
}

export enum TaskStatus {
  OPEN = "OPEN",
  ARCHIVE = "ARCHIVE",
  PRIVATE = "PRIVATE",
  NEW = "NEW"
}

export interface InvitedMember {
  id: string;
  role: RoleToInvite;
}

export interface UserAccount {
  id: number;
  avatar: string;
  email: string;
  token: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  tasks: number;
  balance: number;
  status: string;
  endDate: string;
  skills: Skill[];
  links: Link[];
  country: string;
  aboutMe: string;
  locked: boolean;
  enabled: boolean;
  listTasks: Task[];
}

export interface IUserLogin {
  email: string;
  password: string;
}

export interface IRegisterUser {
  email: string;
  password: string;
}

export interface IUserConfirm {
  id: number;
  code: string;
}

export interface IUpdateUser {
  displayName?: string;
  photoURL?: string;
}

export interface ICreateChat {
  taskId: string;
  name?: string;
  type?: TypeChat;
  members?: string[];
}

export interface ICreateTask {
  id?: string;
  ownerId?: string;
  name?: string;
  type?: TypeTask;
  level?: number;
  area?: string;
  collaboration?: string;
  deadline?: Date;
  budget?: number;
  technologies?: string[];
  members?: number;
  message?: string;
  chats?: Chat[];
  created_at?: string;
  status?: string;
}

export interface ISendMessage {
  messageId?: string;
  owner?: string;
  date?: string;
  chatId?: string;
  text: string;
  taskId?: string;
}

export interface IError {
  code: string;
  message: string;
}

export interface IRegError {
  code: string;
  message: string;
}

export enum RoleToInvite {
  USER = "USER",
  MODERATOR = "MODERATOR",
  ASSISTANT = "ASSISTANT",
  DELETE = "DELETE"
}
