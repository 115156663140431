import styled from "styled-components";
import {IconWrapper} from "../RegisterForm/styles";
import ArrowDownIcon from "../../assets/AddowDownIcon";
import SettingsIcon from "../../assets/SettingsIcon";
import SearchIcon from "../../assets/SearchIcon";
import BillingIcon from "../../assets/BillingIcon";
import CodeIcon from "../../assets/CodeIcon";


export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1440px;
  max-height: 1024px;
  min-width: 95%;
  min-height: 95%;
  border-radius: 24px;
  background: rgb(9, 32, 39);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const MenuContainer = styled.div`
  border-radius: 24px;
  box-shadow: 0 8px 10px -6px rgba(6, 7, 8, 0.06),0px 25px 50px -12px rgba(6, 7, 8, 0.16);
  background: rgb(14, 23, 27);
  position: static;
  width: 310px;
  height: 96px;
  margin: 12px;
  padding-left: 10px;
  flex-grow: 1;
`;
export const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 100;
`;
export const TopBlock = styled.div`
  color: aliceblue;
  position: fixed;
  top: 0;
  left: 0;
  width: 312px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid rgb(9, 32, 39);
`;
export const Avatar = styled.img`
  position: absolute;
  width: 48px;
  height: 48px;
  margin: 0 16px;
  border-radius: 50%;
`;

export const AvatarBlock = styled.div`
  position: static;
  width: 115px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;


export const NameBlock = styled.div`
    position: relative;
    margin-top: 5px;
    margin-left: 230px;
    width: 146px;
    max-width: 146px;
    height: 18px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: none;
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
export const TasksBlock = styled.div`
  position: relative;
  margin-left: 200px;
  width: 100%;
  color: rgb(182, 240, 156);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.15px;
`;
export const IconsBlock = styled.div`
  position: absolute;
  top: 55%;
  right: 25%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 16px;
  height: 16px;
`;

export const ArrowIconWrapper = styled(IconWrapper)`
  top: 50%;
  cursor: pointer;
`;

export const ArrowIconStyled = styled(ArrowDownIcon)`
  width: 24px;
  height: 24px;
`;
export const SearchIconStyled = styled(SearchIcon)`
  width: 24px;
  height: 24px;
`;
export const CodeIconStyled = styled(CodeIcon)`
  width: 24px;
  height: 24px;
`;

export const BillingIconStyled = styled(BillingIcon)`
  width: 24px;
  height: 24px;
`;
export const SettingsIconStyled = styled(SettingsIcon)`
  width: 24px;
  height: 24px;
`;
export const GeneralBlock = styled.div`
  position: relative;
  top: 10%;
  width: 312px;
  height: auto;
  padding: 24px 8px 12px 8px;
  border-bottom: 1px solid rgb(9, 32, 39);
`;

export const TaskBlock = styled.div`
  position: relative;
  top: 10%;
  width: 312px;
  height: auto;
  padding: 24px 8px 12px 8px;
  border-bottom: 1px solid rgb(9, 32, 39);
`;

export const GeneralText = styled.div`
    color: rgb(104, 107, 110);
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.15px;
    text-align: left;
    margin-left: 10px;
`;

export const UserContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 296px;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 24px 0;
  box-sizing: border-box;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 16px;
  background: linear-gradient(145.32deg, rgba(215, 237, 237, 0.16) -33.786%,rgba(204, 235, 235, 0) 100%);
`;

export const StatusBlock = styled.div`
  position: absolute;
  left: 30%;
  bottom: 25%;
  width: 46px;
  height: 18px;
  color: rgb(182, 240, 156);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.15px;
`;

export const NameStatusBlock = styled.div`
    position: absolute;
    left: 30%;
    bottom: 55%;
    width: auto;
    max-width: 130px;
    height: 18px;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const ElementsBlock = styled.div`
  position: static;
  width: 296px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 14px 16px 0 16px;
  color: rgb(232, 233, 233);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.15px;
  text-align: center;
  border-radius: 8px;
  box-sizing: border-box;

  &:hover {
    background: linear-gradient(145.32deg, rgba(215, 237, 237, 0.16) -30.47%, rgba(204, 235, 235, 0) 100%);
    cursor: pointer;
  }

  &:active {
    background: linear-gradient(145.32deg, rgba(114,152,152,0.16) -30.47%, rgba(204, 235, 235, 0) 100%);
    cursor: pointer;
    transform: scale(0.98);
  }
`;

export const MenuIconWrapper = styled.div`
  justify-content: left;
  width: 24px;
  height: 24px;
  margin: 1% 10px 5px 0;
`;
export const AddNewTaskBlock = styled.div`
  position: static;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 20px;
  margin-bottom: 10px;
  color: rgb(104, 107, 110);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.15px;
`;
export const UserPersonalBlock = styled.div`
  position: relative;
  top: 10%;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 24px 10px 24px;
  margin: 0 10px;
  border-radius: 20px;
  background: rgb(14, 23, 27);
  border-bottom: 1px solid rgb(9, 32, 39);
`;
export const UserBalance = styled.div`
  position: relative;
  width: 200px;
  height: 113px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 24px 10px 24px;
  margin: 0 10px;
  border-radius: 20px;
  background: rgb(14, 23, 27);
  opacity: 0.8;
`;
export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%; 
`;
export const BalanceItem = styled.div`
  position: absolute;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left; 
  width: fit-content; 
  align-self: flex-start; 
`;
