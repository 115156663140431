import React, { lazy, Suspense } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "./AppRouter";
import { GlobalStyle } from "./style/GlobalStyle";
import { useAppSelector } from "./store/hooks";
import OverlayLoading from "./components/OverlayLoading/OverlayLoading";


export const App = () => {
  const loading = useAppSelector(state => state.app.loading);

  return (
    <BrowserRouter>
      <Suspense fallback={<OverlayLoading />}>
        {loading ? <OverlayLoading /> : null}
        <GlobalStyle />
        <AppRouter />
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
