import React from "react";

const AlertIcon: React.FC = () => {
    return (
        <svg width="48.000000" height="48.000000" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <desc>
                Created with Pixso.
            </desc>
            <defs>
                <filter id="filter_324_4105_dd" x="0.000000" y="0.000000" width="48.000000" height="48.000000" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feMorphology radius="-4" in="" result="BackgroundImageFix"/>
                    <feOffset dx="0" dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.81569 0 0 0 0 0.18824 0 0 0 0 0.18431 0 0 0 0.16 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feMorphology radius="-3" in="" result="effect_dropShadow_1"/>
                    <feOffset dx="0" dy="10"/>
                    <feGaussianBlur stdDeviation="5"/>
                    <feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.81569 0 0 0 0 0.18824 0 0 0 0 0.18431 0 0 0 0.16 0"/>
                    <feBlend mode="normal" in2="effect_dropShadow_1" result="effect_dropShadow_2"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_2" result="shape"/>
                </filter>
            </defs>
            <g filter="url(#filter_324_4105_dd)">
                <path id="icon" d="M24 10L24 14M24 18L24.0098 18M30.2939 8.63403C31.2222 9.16992 31.7939 10.1602 31.7939 11.2322L31.7939 16.7681C31.7939 17.8398 31.2222 18.8301 30.2939 19.366L25.5 22.134C24.5718 22.6699 23.4282 22.6699 22.5 22.134L17.7056 19.366C16.7773 18.8301 16.2056 17.8398 16.2056 16.7681L16.2056 11.2322C16.2056 10.1602 16.7773 9.16992 17.7056 8.63403L22.5 5.86597C23.4282 5.33008 24.5718 5.33008 25.5 5.86597L30.2939 8.63403Z" stroke="#D0302F" strokeOpacity="1.000000" strokeWidth="1.500000"/>
            </g>
        </svg>
);
};

export default AlertIcon
