import React from "react";
import {
    Container,
    Text,
} from "./styles";


const LibraryBlock: React.FC = () => {


    return (
        <Container>
            <Text>Library block</Text>
        </Container>

    );
};

export default LibraryBlock;
