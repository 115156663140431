import styled, { keyframes } from "styled-components";

const waveAnimation = keyframes`
  0% {
    transform: scale(1);
    color: rgb(135, 221, 238)
  }
  50% {
    transform: scale(1.1);
    color: white;
  }
  100% {
    transform: scale(1);
    color: rgb(135, 221, 238)
  }
`;

export const Text = styled.p`
    position: fixed;
    top: 50%;
    left: 50%;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    color: aliceblue;
    transform: translate(-50%, -50%);
`;
export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 11;
    color: white;
`;
export const Letter = styled.span< { delay: string }>`
  display: inline-block;
  animation: ${waveAnimation} 2s infinite;
  animation-delay: ${props => props.delay};
`;
export const LogoContainer = styled.div`
    position: relative;
`;
