import React from "react";

const SettingsIcon: React.FC = () => {
    return (
        <svg width="48.000000" height="48.000000" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <path id="icon" d="M10.8281 9.75C10.8281 10.8546 9.93262 11.75 8.82812 11.75C7.72363 11.75 6.82812 10.8546 6.82812 9.75C6.82812 8.64539 7.72363 7.75 8.82812 7.75C9.93262 7.75 10.8281 8.64539 10.8281 9.75ZM1.05493 5.2135C1.5957 4.2771 2.79297 3.9563 3.72949 4.49695L3.97363 4.63782C4.54614 4.96838 5.25146 4.88696 5.82349 4.55542C6.39526 4.224 6.82812 3.64954 6.82812 2.98853L6.82812 2.70728C6.82812 1.62634 7.70435 0.75 8.7854 0.75L8.87085 0.75C9.9519 0.75 10.8281 1.62634 10.8281 2.70728L10.8281 2.98853C10.8281 3.64954 11.261 4.224 11.8328 4.55542C12.4048 4.88696 13.1101 4.96838 13.6826 4.63782L13.9268 4.49695C14.8633 3.95618 16.0605 4.2771 16.6013 5.2135L16.6431 5.28577C17.1838 6.22266 16.863 7.42053 15.926 7.96143L15.6833 8.10156C15.1106 8.43213 14.8281 9.08875 14.8281 9.75C14.8281 10.4113 15.1106 11.0679 15.6833 11.3984L15.926 11.5386C16.863 12.0795 17.1838 13.2773 16.6431 14.2142L16.6013 14.2865C16.0605 15.2229 14.8633 15.5438 13.9268 15.0031L13.6826 14.8622C13.1101 14.5316 12.4048 14.613 11.8328 14.9446C11.261 15.276 10.8281 15.8505 10.8281 16.5115L10.8281 16.7927C10.8281 17.8738 9.9519 18.75 8.87085 18.75L8.7854 18.75C7.70435 18.75 6.82812 17.8738 6.82812 16.7927L6.82812 16.5115C6.82812 15.8505 6.39526 15.276 5.82349 14.9446C5.25146 14.613 4.54614 14.5316 3.97363 14.8622L3.72949 15.0031C2.79297 15.5437 1.5957 15.2229 1.05493 14.2865L1.01318 14.2141C0.472412 13.2773 0.793213 12.0795 1.73022 11.5386L1.9729 11.3984C2.54565 11.0679 2.82812 10.4113 2.82812 9.75C2.82812 9.08875 2.54565 8.43213 1.9729 8.10156L1.73022 7.96143C0.793213 7.42053 0.472412 6.22266 1.01318 5.28589L1.05493 5.2135Z" stroke="#686B6E" strokeOpacity="1.000000" strokeWidth="1.500000" strokeLinejoin="round"/>
        </svg>
);
};

export default SettingsIcon