import React from "react";

const PlusIconSvg: React.FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    >
            <path id="icon" d="M8.25 4.91675L8.25 8.25L8.25 11.5833M11.5833 8.25L8.25 8.25M8.25 8.25L4.91675 8.25M8.25 15.75C4.10791 15.75 0.75 12.3921 0.75 8.25C0.75 4.10791 4.10791 0.75 8.25 0.75C12.3921 0.75 15.75 4.10791 15.75 8.25C15.75 12.3921 12.3921 15.75 8.25 15.75Z" stroke="#363A3D" strokeOpacity="1.000000" strokeWidth="1.500000"/>
        </svg>
);
};

export default PlusIconSvg