import React from "react";

const MailIcon: React.FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path id="icon" d="M16.75 0.75C17.8546 0.75 18.75 1.64542 18.75 2.75L18.75 11.75C18.75 12.5784 18.4142 13.3284 17.8713 13.8713C17.3284 14.4142 16.5784 14.75 15.75 14.75L3.75 14.75C2.92157 14.75 2.17157 14.4142 1.62868 13.8713C1.08578 13.3284 0.75 12.5784 0.75 11.75L0.75 2.75C0.75 1.64542 1.64543 0.75 2.75 0.75L16.75 0.75ZM1.75 1.75L7.75 7.75L8.33578 8.33578C9.11684 9.11682 10.3832 9.11682 11.1642 8.33578L11.75 7.75L17.75 1.75M17.8713 13.8713L11.75 7.75M1.62868 13.8713L7.75 7.75" stroke="#686B6E" strokeOpacity="1.000000" strokeWidth="1.500000" strokeLinecap="round"/>
        </svg>
    );
};

export default MailIcon