import React, {useState} from "react";
import {
    AddNewTaskBlock,
    ArrowIconStyled,
    ArrowIconWrapper,
    Avatar,
    AvatarBlock, BalanceItem,
    BillingIconStyled,
    ElementsBlock,
    GeneralBlock,
    GeneralText,
    IconsBlock, InnerContainer,
    MenuContainer,
    MenuIconWrapper,
    NameBlock,
    NameStatusBlock, PopupContainer,
    SearchIconStyled,
    SettingsIconStyled,
    StatusBlock,
    TaskBlock,
    TasksBlock,
    TopBlock, UserBalance,
    UserContainer, UserPersonalBlock
} from "./styles";
import {CircleIcons, CodeIcons, DefaultAvatar, LampIcon, PlusIcon, SquareIcon} from "../../assets";
import {MenuImage} from "../TopBlock/styles";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {IData, Task} from "../../store/types";
import {parseISO} from "date-fns";
import {format} from "date-fns";
import {SecondItem} from "../BillingComponent/styles";
import {logoutFunc} from "../../store/slices/userSlice";
import {getAuth} from "firebase/auth";
import {AlertIconWrapper, AlertMessage} from "../../style/GlobalStyle";
import AlertIcon from "../../assets/AlertIcon";


const GlobalMenuBlock: React.FC = () => {
    const stateUser = useAppSelector(state => state.app?.user);
    const userTasks: Task[] = useAppSelector(state => state.app.tasks || []);
    const auth = getAuth();
    const user = auth.currentUser;
    const data: IData = useAppSelector(state => state.app.data!)
    const navigate = useNavigate();
    const [userBlockOpen, setUserBlockopen] = useState(false);
    const parsedEndDate = data && data.endDate ? parseISO(data.endDate) : parseISO("2000-01-01T13:48:00.000000");
    const formattedEndDate = format(parsedEndDate, 'yyyy-MM-dd');
    const dispatch = useAppDispatch();
    const handleLogOut = () => {
        dispatch(logoutFunc() as any);
    };
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const handleAvatarClick = () => {
        setIsPopupOpen(!isPopupOpen);
    };
    const getTaskLabel = (count: number) => {
        if (count === 1) {
            return 'task';
        } else {
            return 'tasks';
        }
    };
    return (
        <MenuContainer>
            <TopBlock>
                <AvatarBlock>
                    <Avatar onClick={handleAvatarClick} src={user?.photoURL || stateUser?.photoURL || DefaultAvatar}
                            alt=""/>
                    {(!user?.emailVerified || !user.photoURL || !user.displayName) &&
                        <AlertIconWrapper onMouseEnter={() => setShowAlert(true)}
                                          onMouseLeave={() => setShowAlert(false)}
                        >
                            <AlertIcon/>
                            {showAlert &&
                                <>
                                    <AlertMessage>{!user?.emailVerified && "Please verify your email"}</AlertMessage>
                                    <AlertMessage>{!user?.photoURL && "Please upload your avatar"}</AlertMessage>
                                    <AlertMessage>{!user?.displayName && "Please enter your name"}</AlertMessage>
                                </>
                            }
                        </AlertIconWrapper>}
                    {isPopupOpen && (
                        <PopupContainer>

                        </PopupContainer>
                    )}
                    <NameBlock>{user?.displayName || stateUser?.displayName || "New user"}</NameBlock>
                    <TasksBlock>{userTasks.length} {getTaskLabel(userTasks.length)}</TasksBlock>
                    <IconsBlock>
                        <ArrowIconWrapper onClick={() => setUserBlockopen(!userBlockOpen)}>
                            <ArrowIconStyled/>
                        </ArrowIconWrapper>
                    </IconsBlock>
                </AvatarBlock>
            </TopBlock>

            {userBlockOpen && (
                <UserPersonalBlock>

                    <UserBalance>
                        <InnerContainer>
                            <BalanceItem>Balance:</BalanceItem>
                            <SecondItem>{data?.balance || 0}$</SecondItem>
                        </InnerContainer>
                        <InnerContainer>
                            <BalanceItem>Status:</BalanceItem>
                            <SecondItem>{data?.status || "Free"}</SecondItem>
                        </InnerContainer>
                        <InnerContainer>
                            <BalanceItem>End date:</BalanceItem>
                            <SecondItem>{formattedEndDate}</SecondItem>
                        </InnerContainer>
                        <InnerContainer>
                            <SecondItem onClick={handleLogOut} style={{cursor: "pointer"}}> Logout</SecondItem>
                        </InnerContainer>
                    </UserBalance>
                </UserPersonalBlock>)}
            <GeneralBlock>
                <GeneralText>GENERAL</GeneralText>
                <ElementsBlock onClick={() => navigate("/home/assistants")}>
                    <MenuIconWrapper>
                        <SearchIconStyled/>
                    </MenuIconWrapper>
                    Assistants
                </ElementsBlock>
                <ElementsBlock onClick={() => navigate("/home/billing")}>
                    <MenuIconWrapper>
                        <BillingIconStyled/>
                    </MenuIconWrapper>
                    Billing
                </ElementsBlock>
            </GeneralBlock>
            <TaskBlock>
                <GeneralText>TASKS</GeneralText>
                <ElementsBlock onClick={() => navigate("/home/tasks/web")}>
                    <MenuIconWrapper>
                        <MenuImage src={SquareIcon}/>
                    </MenuIconWrapper>
                    Web application
                </ElementsBlock>
                <ElementsBlock onClick={() => navigate("/home/tasks/problems")}>
                    <MenuIconWrapper>
                        <MenuImage src={CodeIcons}/>
                    </MenuIconWrapper>
                    Problem-Solving
                </ElementsBlock>
                <ElementsBlock onClick={() => navigate("/home/tasks/mobile")}>
                    <MenuIconWrapper>
                        <MenuImage src={CircleIcons}/>
                    </MenuIconWrapper>
                    Mobile app
                </ElementsBlock>
                <ElementsBlock onClick={() => navigate("/home/tasks/startups")}>
                    <MenuIconWrapper>
                        <MenuImage src={LampIcon}/>
                    </MenuIconWrapper>
                    Startups & Hackathons
                </ElementsBlock>
                <ElementsBlock onClick={() => navigate("/home/create")}>
                    <AddNewTaskBlock>
                        <MenuIconWrapper>
                            <MenuImage src={PlusIcon}/>
                        </MenuIconWrapper>
                        Add new task
                    </AddNewTaskBlock>
                </ElementsBlock>
            </TaskBlock>
            <UserContainer>
                <Avatar src={user?.photoURL || stateUser?.photoURL || DefaultAvatar} alt=""/>
                <NameStatusBlock>{user?.displayName || stateUser?.displayName || "New user"}</NameStatusBlock>
                <StatusBlock>{data?.status || "Free"}</StatusBlock>
                <IconsBlock>
                    <ArrowIconWrapper onClick={() => navigate("/home/settings")}>
                        <SettingsIconStyled/>
                    </ArrowIconWrapper>
                </IconsBlock>
            </UserContainer>
        </MenuContainer>
    );
};

export default GlobalMenuBlock;
