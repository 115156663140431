import React from "react";

const BillingIcon: React.FC = () => {
    return (
        <svg width="48.000000" height="48.000000" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <path id="icon" d="M14.0833 0.75C15.0039 0.75 15.75 1.49622 15.75 2.41663L15.75 3.66663L15.75 10.75C15.75 11.6705 15.0039 12.4166 14.0833 12.4166L2.41675 12.4166C1.49609 12.4166 0.75 11.6705 0.75 10.75L0.75 3.66663L0.75 2.41663C0.75 1.49622 1.49609 0.75 2.41675 0.75L14.0833 0.75ZM0.75 3.66663L15.75 3.66663M3.25 9.91663L6.58325 9.91663" stroke="#686B6E" strokeOpacity="1.000000" strokeWidth="1.500000" strokeLinecap="round"/>
        </svg>
);
};

export default BillingIcon