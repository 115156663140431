import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AuthState,
  IAuthResponse,
  ICreateChat,
  ICreateTask,
  IData,
  IError,
  INewUser,
  IRegisterUser,
  ISendMessage,
  IUpdateUser,
  IUserLogin, OnlineUsers,
  Task
} from "../types";
import authService from "./authService";
import AuthService from "./authService";

export const loginUserAsync = createAsyncThunk<
  IAuthResponse, IUserLogin,
  { rejectValue: IError }
>(
  "user/login",
  async (payload: IUserLogin, { rejectWithValue }) => {
    try {
      const response = await AuthService.login(payload);
      if ("code" in response) {
        return rejectWithValue(response as IError);
      }
      return response;
    } catch (error: any) {
      return rejectWithValue({
        code: error.code || "unknown_error",
        message: error.message
      });
    }
  }
);
export const loginGoogleUserAsync = createAsyncThunk(
  "user/loginGoggle",
  async (payload: IUserLogin, { rejectWithValue }) => {
    try {
      const response = await AuthService.googleLogin();
      if ("code" in response) {
        return rejectWithValue(response as IError);
      }
      return response;
    } catch (error: any) {
      return rejectWithValue({
        code: error.code || "unknown_error",
        message: error.message
      });
    }
  }
);
export const sendResetPasswordMail = createAsyncThunk(
  "user/resetpassword",
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await AuthService.resetPassword(email);
      if ("code" in response) {
        return rejectWithValue(response as IError);
      }
      return response;
    } catch (error: any) {
      return rejectWithValue({
        code: error.code || "unknown_error",
        message: error.message
      });
    }
  }
);
export const loginFacebookUserAsync = createAsyncThunk(
  "user/loginFacebook",
  async (payload: IUserLogin, { rejectWithValue }) => {
    try {
      const response = await AuthService.facebookLogin();
      if ("code" in response) {
        return rejectWithValue(response as IError);
      }
      return response;
    } catch (error: any) {
      return rejectWithValue({
        code: error.code || "unknown_error",
        message: error.message
      });
    }
  }
);
export const RegisterUserAsync = createAsyncThunk<
  IAuthResponse, IRegisterUser,
  { rejectValue: IError }
>(
  "auth/register",
  async (payload: IRegisterUser, { rejectWithValue }) => {
    try {
      const response = await authService.register(payload);
      if ("code" in response) {
        return rejectWithValue(response as IError);
      }
      return response;
    } catch (error: any) {
      return rejectWithValue({
        code: error.code || "unknown_error",
        message: error.message
      });
    }
  }
);
export const addBalanceAsync = createAsyncThunk(
  "user/addBalance",
  async (payload: { userId: string }, { rejectWithValue }) => {
    try {
      await AuthService.addBalance(payload.userId);
    } catch (error: any) {
      return rejectWithValue({
        code: error.code || "unknown_error",
        message: error.message
      });
    }
  }
);
export const CreateTaskAsync = createAsyncThunk(
  "auth/createTask",
  async (credentials: ICreateTask, { rejectWithValue }) => {
    try {
      await authService.createTask(credentials);
    } catch (error: any) {
      return rejectWithValue({
        code: error.code || "unknown_error",
        message: error.message
      });
    }
  }
);

export const UpdateUserAsync = createAsyncThunk(
  "auth/update",
  async (credentials: IUpdateUser, { rejectWithValue }) => {
    try {
      await authService.update(credentials);
    } catch (error: any) {
      return rejectWithValue({
        code: error.code || "unknown_error",
        message: error.message
      });
    }
  }
);
export const logoutFunc = createAsyncThunk(
  "auth/logout",
  async (_, thunkAPI) => {
    try {
      await authService.logOut();
      window.location.href = "/";
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const CreateChatAsync = createAsyncThunk(
  "auth/createChat",
  async (credentials: ICreateChat, thunkAPI) => {
    try {
      await authService.createChat(credentials);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const SendMessageAsync = createAsyncThunk(
  "auth/sendMessage",
  async (credentials: ISendMessage, thunkAPI) => {
    try {
      await authService.sendMessage(credentials);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


const initialState: AuthState = {
  loading: false,
  user: null as INewUser | null,
  data: null as IData | null,
  error: null,
  onlineUsers: null as OnlineUsers[] | null,
  tasks: null as Task[] | null
};
const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    setUserTasks: (state, action: PayloadAction<any>) => {
      state.tasks = action.payload;
    },
    setOnlineUsers: (state, action) => {
      state.onlineUsers = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUserAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user as INewUser;
        state.data = action.payload.data as IData;
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = { code: "unknown_error", message: "An unknown error occurred" };
        }
      })
      .addCase(loginGoogleUserAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginGoogleUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user as INewUser;
        state.data = action.payload.data as IData;
      })
      .addCase(loginGoogleUserAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(loginFacebookUserAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginFacebookUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user as INewUser;
        state.data = action.payload.data as IData;
      })
      .addCase(loginFacebookUserAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(RegisterUserAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(RegisterUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user as INewUser;
        state.data = action.payload.data as IData;
      })
      .addCase(RegisterUserAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as IError;
      })
      .addCase(sendResetPasswordMail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendResetPasswordMail.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(sendResetPasswordMail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as IError;
      })
      .addCase(UpdateUserAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdateUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        // state.error = {
        //   code: "ok",
        //   message: "Message sent successfully"
        // }
      })
      .addCase(UpdateUserAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as IError;
      })
      .addCase(logoutFunc.fulfilled, (state, action) => {
        Object.assign(state, initialState);
      })
      .addCase(CreateChatAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(CreateChatAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(CreateChatAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as IError;
      })
      .addCase(SendMessageAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(SendMessageAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(SendMessageAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as IError;
      })
      .addCase(CreateTaskAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(CreateTaskAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(CreateTaskAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as IError;
      });
  }
});

export const { setOnlineUsers, setData, setUserTasks } = userSlice.actions;
export default userSlice.reducer;
