import React, { useEffect, useRef, useState } from "react";
import {
  GoogleGenerativeAI,
  StartChatParams, ChatSession
} from "@google/generative-ai";
import { Container } from "./styles";
import {
  Button,
  ChatBlock,
  CopyIconWrapper,
  DescriptionText,
  FixedMessage,
  IconStyled,
  InputChat,
  Message,
  MessageBlock,
  NameBlock,
  NameChatBlock,
  NameChatText,
  NewPublicMessages,
  PlanetIconStyled, PublicChatsBlock, RightBlockFixed,
  SendMessageButtonClick
} from "./styles";
import { CopyIcon, microphone, PlanetIcon, Screpka, SendMessageButton } from "../../assets";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, set } from "firebase/database";
import { v4 as uuidv4 } from "uuid";
import { ArrowIconStyled } from "../MenuBlock/styles";
import { IData, OnlineUsers } from "../../store/types";
import { useAppSelector } from "../../store/hooks";

interface ChatCommand {
  command: string;
  description: string;
  action: () => void;
}

const API_KEY = "AIzaSyC2Bhh031uhHp6jcUJ8Vb2gfcPpC6CSz8I";
const MODEL_NAME = "gemini-1.5-flash";
const Assistants: React.FC = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  const onlineUsers: OnlineUsers[] | null = useAppSelector(state => state.app?.onlineUsers);
  const data: IData | null = useAppSelector(state => state.app?.data);
  const [chatHistory, setChatHistory] = useState<string[]>([]);
  const [userInput, setUserInput] = useState<string>("");
  const [chatSession, setChatSession] = useState<ChatSession | null>(null);
  const messageBlockRef = useRef<HTMLDivElement | null>(null);
  const db = getDatabase();
  const chatIdRef = useRef<string>(uuidv4());
  const chatsArray = Object.entries(data?.assistentChats || {});
  const [chatsOpen, setChatsOpen] = useState(false);
  const [showCommands, setShowCommands] = useState(false); // Состояние для отображения списка команд
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };
  const showOnlineUsers = () => {
    addToChatHistory("Fetching online users...");
    if (onlineUsers) {
      const usersOnline = onlineUsers.map(user => user.userData.name);
      addToChatHistory(`Online Users: ${usersOnline.join(", ")}`);
    } else {
      addToChatHistory("No users are currently online.");
    }
  };
  const [availableCommands] = useState<ChatCommand[]>([
    {
      command: "/usersOnline",
      description: "Show online users",
      action: showOnlineUsers
    }
  ]);

  const handleStartChat = async () => {
    try {
      const genAI = new GoogleGenerativeAI(API_KEY);
      const generativeModel = genAI.getGenerativeModel({ model: MODEL_NAME });

      const chatParams: StartChatParams = {
        history: [
          {
            role: "user",
            parts: [{ text: "Hello, I have 2 dogs in my house." }]
          },
          {
            role: "model",
            parts: [{ text: "Great to meet you. What would you like to know?" }]
          }
        ],
        generationConfig: {
          maxOutputTokens: 100
        }
      };

      const session = generativeModel.startChat(chatParams) as ChatSession;
      setChatSession(session);
      addToChatHistory("Chat session started.");
    } catch (error) {
      console.error("Error starting chat session:", error);
      addToChatHistory("Error starting chat session.");
    }
  };
  useEffect(() => {
    set(ref(db, "users/" + user?.uid + "/assistentChats/" + chatIdRef.current), chatHistory);
    if (messageBlockRef.current) {
      messageBlockRef.current.scrollTop = messageBlockRef.current.scrollHeight;
    }
  }, [chatHistory]);
  const sendMessage = async () => {
    setUserInput("");
    if (!chatSession) return;

    if (userInput === "/usersOnline") {
      if (onlineUsers) {
        const usersOnline = onlineUsers.map(user => user.userData.name);
        addToChatHistory(`Online Users: ${usersOnline.join(", ")}`);
        setShowCommands(false);
      } else {
        addToChatHistory("No users are currently online.");
      }
      return;
    }

    addToChatHistory(`${user?.displayName}: ${userInput}`);
    try {
      const result = await chatSession.sendMessage([{ text: userInput }]);
      const response = result.response;
      const text = response.text();
      addToChatHistory(`Chat: ${text}`);
    } catch (error) {
      console.error("Error sending message:", error);
      addToChatHistory("Error sending message.");
    }
  };

  const addToChatHistory = (message: string) => {
    setChatHistory(prev => [...prev, message]);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.startsWith("/")) {
      setShowCommands(true);
    } else {
      setShowCommands(false);
    }
    setUserInput(event.target.value);
  };
  const executeCommand = (command: string) => {
    setUserInput("");
    if (!chatSession) return;
    const selectedCommand = availableCommands.find(cmd => cmd.command === command);
    if (selectedCommand) {
      selectedCommand.action();
    } else {
      addToChatHistory(`Command '${command}' not recognized.`);
    }
  };

  return (
    <Container>
      {!chatSession && <Button onClick={handleStartChat}>Start Chat</Button>
      }
      <MessageBlock ref={messageBlockRef}>
        {chatHistory?.map((message, index) => (
          <Message key={index}>
            <FixedMessage>
              <CopyIconWrapper onClick={() => setUserInput("Re to :" + message)} src={CopyIcon} />
              <DescriptionText>{message || ""}</DescriptionText>
            </FixedMessage>
          </Message>
        ))}
      </MessageBlock>
      <ChatBlock>
        <IconStyled src={microphone} />
        <InputChat value={userInput} onChange={handleInputChange} type="text"
                   placeholder="You can ask me anything! I am here to help."
                   $hasText={""} onKeyDown={handleKeyPress} />
        {showCommands && (
          <div style={{ zIndex: 100, color: "white" }}>
            {availableCommands.map((cmd, index) => (
              <div key={index} onClick={() => executeCommand(cmd.command)}>
                {cmd.command} - {cmd.description}
              </div>
            ))}
          </div>
        )}
        <IconStyled style={{ position: "absolute", right: "130px" }} src={Screpka} />
        <SendMessageButtonClick onClick={sendMessage} src={SendMessageButton} />
      </ChatBlock>
      <RightBlockFixed>
        <PublicChatsBlock>
          <NameBlock onClick={() => setChatsOpen(!chatsOpen)}>
            <ArrowIconStyled />
            Assistent Chats
            <NewPublicMessages>{chatsArray.length}</NewPublicMessages></NameBlock>
          {chatsOpen && chatsArray.map(([chatId, chat]) => (
            <NameChatBlock key={chatId}>
              <PlanetIconStyled src={PlanetIcon} />
              <NameChatText>{`Chat ID: ${chatId}`}</NameChatText>
            </NameChatBlock>
          ))}
        </PublicChatsBlock>
      </RightBlockFixed>
    </Container>
  );
};

export default Assistants;
