import styled, {css} from "styled-components";

interface ButtonProps {
    isSelected?: boolean;
}

export const Container = styled.div`
    position: absolute;
    justify-content: right;
    top: 195px;
    left: 350px;
    right: 100px;
    bottom: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(9, 32, 39);
`;

export const Text = styled.p`
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    color: aliceblue;
`;

export const TopBlock = styled.div`
    position: relative;
    display: flex;
    height: 58px;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    box-sizing: border-box;
`;
export const Button = styled.button<ButtonProps>`
    position: relative;
    cursor: pointer;
    border: 0;
    width: 146px;
    height: 58px;
    padding: 16px 24px 16px 24px;
    margin-right: 30px;
    box-sizing: border-box;
    border-radius: 8px;
    background: rgb(26, 29, 33);
    color: rgb(104, 107, 110);
    font-size: 18px;
    font-weight: 400;
    line-height: 146%;
    text-align: center;

    &:hover {
        background: linear-gradient(45.00deg, rgb(130, 219, 247) 0%, rgb(182, 240, 156) 100%);
        color: rgb(6, 7, 8);
    }

    ${(props) =>
            props.isSelected &&
            css`
                background: linear-gradient(45.00deg, rgb(130, 219, 247) 0%, rgb(182, 240, 156) 100%);
                color: rgb(6, 7, 8);
            `}
    &:active {
        transform: scale(0.99);
    }
`;

export const Line = styled.div`
    position: relative;
    width: 100%;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    border: 1px solid rgb(54, 58, 61);
    margin-top: 5px;
    margin-bottom: 5px;
`;
export const AllTasks = styled.div`
`;
export const ButtonsBlock = styled.div`
    position: relative;
    height: 58px;
    justify-content: flex-start;
`;

export const Element = styled.div`
    position: relative;
    width: 100%;
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
        background: rgb(14, 23, 27);
    }
`;
export const Table = styled.div`
    position: relative;
    width: 100%;
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const Toggle = styled.div<{ $isChecked?: boolean }>`
    height: 10px;
    width: 10px;
    position: relative;
    background: ${(props) =>
            props.$isChecked
                    ? "linear-gradient(45.00deg, rgb(77, 98, 229) 0%,rgb(135, 221, 238) 45.313%,rgb(182, 240, 156) 100%)"
                    : "rgb(26, 29, 33)"};
    padding: 10px;
    margin-left: 50px;
    cursor: pointer;
    box-shadow: ${(props) =>
            props.$isChecked
                    ? "0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px -1px rgba(0, 0, 0, 0.05)"
                    : "none"};
    border: 1px solid ${(props) => (props.$isChecked ? "rgb(182, 240, 156)" : "rgb(54, 58, 61)")};
    border-radius: 4px;

    &:hover {
        border-color: ${(props) => (props.$isChecked ? "rgb(135, 221, 238)" : "rgb(182, 240, 156)")};
    }

    &::after {
        content: "${(props) => (props.$isChecked ? "\u2714" : "")}";
        font-size: 18px;
        font-weight: bold;
        color: #000;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: ${(props) => (props.$isChecked ? "1" : "0")};
        transition: opacity 0.2s ease;
    }

    @media (max-width: 768px) {
        left: 10%;
    }
`;
export const Title = styled.p`
    position: relative;
    width: 150px;
    color: rgb(155, 156, 158);

    font-size: 18px;
    font-weight: 400;
    line-height: 153%;
    letter-spacing: 0;
`;
export const Col = styled.p`
    position: relative;
    width: 150px;
    color: rgb(94, 94, 94);
    font-size: 18px;
    font-weight: 400;
    line-height: 153%;
    letter-spacing: 0;
`;
export const TitleText = styled.div`
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0;
    text-align: left;
`;
export const SmallTitleText = styled.div`
    color: rgb(155, 156, 158);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: left;
`;
export const DoubleBlock = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

`;
export const DropDownBlock = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background: rgb(14, 23, 27);
    //&:hover {
    //    border-color: rgb(130, 219, 247);
    //    box-shadow: 0 0 0 1px rgb(182, 240, 156), 0 0 5px 4px rgba(132, 220, 245, 0.24);
    //    border-radius: 8px;
    //}
`;
export const SelectUserBlock = styled.select`
    display: flex;
    position: relative;
    width: auto;
    height: 48px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    border-radius: 12px;
    background: linear-gradient(145.32deg, rgba(215, 237, 237, 0.16) -43.025%, rgba(204, 235, 235, 0) 100%);
    color: rgb(182, 240, 156);
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.15px;
    text-align: left;
    &:before {
        content: '';
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        border: 1px solid transparent;
        border-radius: 8px;
        transition: border-color 0.3s ease;
        box-sizing: border-box;
    }
    &:hover {
        background: linear-gradient(145.32deg, rgba(160, 177, 177, 0.16) -43.025%, rgba(204, 235, 235, 0) 100%);
        color: rgb(122, 159, 105);
    }
    &:focus {
        border-color: rgb(130, 219, 247);
        box-shadow: 0 0 0 1px rgb(182, 240, 156), 0 0 5px 4px rgba(132, 220, 245, 0.24);
    }

    &:focus::before {
        border-color: rgba(132, 220, 245, 0.24);
    }
`;
export const BottomBlock = styled.div`
    position: relative;
    display: flex;
    justify-content: center;

    flex-direction: column;
    align-items: center;
    max-width: 100%;
    height: auto;
    border-radius: 12px;
    background: rgb(9, 32, 39);
    padding: 10px;
`;
export const UserAvatar = styled.img`
    position: static;
    width: 48px;
    height: 48px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 16px;
    border-radius: 50%;
`;
export const UserName = styled.div`
    position: relative;
    width: auto;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: center;
`;
export const UsersTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 4px 0px;
    color: rgb(155, 156, 158);
`;
export const UserToInviteBlock = styled.div`
    display: flex;
    width: 90%;
    height: 30px;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 0;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 16px 0;

    &:hover {
        padding: 10px;
        border-radius: 8px;
        border-color: rgb(130, 219, 247);
        box-shadow: 0 0 0 1px rgb(182, 240, 156), 0 0 5px 4px rgba(132, 220, 245, 0.24);
    }
`;
export const UserBlock = styled.div`
    display: flex;
    width: 90%;
    height: 30px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 16px 0;
`;
export const UserLink = styled.div`
    position: static;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 4px 0px;
    color: rgb(182, 240, 156);
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.15px;
    text-align: center;
`;
export const SendButton = styled.button`
    position: relative;
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    border-radius: 12px;
    background-color: rgb(39, 133, 168);
    color: rgb(12, 17, 50);
    border: none;

    &:hover {
        background-color: rgb(32, 109, 138);
        color: rgb(12, 17, 50);
    }

    &:active {
        transform: scale(0.98);
        background-color: rgb(24, 82, 103);
        color: rgb(12, 17, 50);
    }
`;
export const OpenRequestsButton = styled.button`
    position: relative;
    width: auto;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    border-radius: 12px;
    background-color: rgb(24, 82, 103);
    color: white;
    border: none;

    &:hover {
        background-color: rgb(17, 59, 74);
        color: white;
    }

    &:active {
        transform: scale(0.98);
        background-color: rgb(24, 82, 103);
        color: rgb(12, 17, 50);
    }
`;
export const DeleteUserButton = styled.button`
    position: absolute;
    right: 10px;
    width: auto;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding:  5px 10px;
    border-radius: 12px;
    background-color: rgb(168, 39, 39);
    color: white;
    border: none;

    &:hover {
        background-color: rgb(126, 29, 29);
        color: white;
    }

    &:active {
        transform: scale(0.98);
        background-color: rgb(24, 82, 103);
        color: rgb(12, 17, 50);
    }
`;
export const StatusButton = styled.option`
    box-sizing: border-box;
    border: none;
    border-radius: 12px;
    background: rgb(94, 94, 94);
    color: rgb(182, 240, 156);
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.15px;
    text-align: left;
`;
export const DeleteButton = styled.option`
    box-sizing: border-box;
    border: none;
    border-radius: 12px;
    background: rgb(94, 94, 94);
    color: rgb(182, 240, 156);
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.15px;
    text-align: left;
`;

export const SuggestionList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    border: 1px solid #ccc;
    position: absolute;
    background: white;
    width: 100%;
    z-index: 1;
`;

export const SuggestionItem = styled.li`
    padding: 8px;
    cursor: pointer;

    &:hover {
        background: #eee;
    }
`;
