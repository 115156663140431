import React from "react";

const SearchIcon: React.FC = () => {
    return (
        <svg width="48.000000" height="48.000000" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <path id="icon" d="M0.75 6.81055C0.75 3.46338 3.46338 0.75 6.81055 0.75C10.1577 0.75 12.8711 3.46338 12.8711 6.81055C12.8711 8.48425 12.1929 9.99939 11.0962 11.0961C9.99927 12.1929 8.48413 12.8712 6.81055 12.8712C3.46338 12.8712 0.75 10.1578 0.75 6.81055ZM14.0833 14.0834L11.0962 11.0961" stroke="#686B6E" strokeOpacity="1.000000" strokeWidth="1.500000" strokeLinejoin="round"/>
        </svg>
);
};

export default SearchIcon