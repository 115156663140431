import React, { useEffect } from "react";
import {
  DeleteButton,
  DoubleBlock,
  DropDownBlock, OpenRequestsButton,
  SelectUserBlock,
  SendButton,
  SmallTitleText,
  StatusButton,
  TitleText,
  UserAvatar,
  UserBlock,
  UserName, UserToInviteBlock
} from "./styles";
import { DefaultAvatar } from "../../assets";
import { InvitedMember, OnlineUsers, RoleToInvite, Task, TaskStatus } from "../../store/types";
import { CrossIconWrapper, DialogContentProduct } from "../TopBlock/styles";
import CircleIcon from "../../assets/CircleIcon";
import { Input } from "../../style/GlobalStyle";
import { get, getDatabase, ref, update } from "firebase/database";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getAuth } from "firebase/auth";


interface DialogContentSettingsTaskProps {
  task?: Task,
  setShowBlock?: (value: (((prevState: boolean) => boolean) | boolean)) => void
}

const DialogContentSettingsTask: React.FC<DialogContentSettingsTaskProps> = ({
  task,
  setShowBlock
}: DialogContentSettingsTaskProps) => {
  const onlineUsers = useSelector((state: RootState) => state.app?.onlineUsers);
  const [roleToInvite, setRoleToInvite] = React.useState<RoleToInvite>(RoleToInvite.USER);
  const [inputValue, setInputValue] = React.useState("");
  const [suggestions, setSuggestions] = React.useState<OnlineUsers[]>([]);
  const [taskUsers, setTaskUsers] = React.useState<OnlineUsers[]>([]);
  const [selectedUser, setSelectedUser] = React.useState<OnlineUsers>();
  const [owner, setOwner] = React.useState<boolean>(false);
  const db = getDatabase();
  const auth = getAuth();
  const user = auth.currentUser;
  useEffect(() => {
    if (task?.ownerId === user?.uid) {
      setOwner(true);
    }
    if (onlineUsers) {
      const checkUsers = onlineUsers.filter(user =>
        task?.invitedMembers?.some((member: InvitedMember) => member.id === user.userId));
      setTaskUsers(checkUsers);
    }
  }, [onlineUsers, task?.invitedMembers, task?.ownerId, user?.uid]);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    if (value && onlineUsers) {
      const filteredUsers = onlineUsers.filter(user =>
        (user.userData.name ? user.userData.name : "Anonymous").toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredUsers);
    } else {
      setSuggestions([]);
    }
  };
  const handleSuggestionClick = (user: OnlineUsers) => {
    setInputValue(user.userData.name);
    setSelectedUser(user);
    setSuggestions([]);
  };
  const handleSendClick = async () => {
    if (selectedUser) {
      setSelectedUser(undefined);
      setInputValue("");
      const taskRef = ref(db, `tasks/${task?.id}`);
      try {
        const taskSnapshot = await get(taskRef);
        if (taskSnapshot.exists()) {
          const taskData = taskSnapshot.val();
          const currentInvitedMembers: InvitedMember[] = taskData.invitedMembers || [];

          const isAlreadyInvited = currentInvitedMembers.some(member => member.id === selectedUser.userId);

          if (!isAlreadyInvited) {
            const updatedInvitedMembers = [
              ...currentInvitedMembers,
              { id: selectedUser.userId, role: roleToInvite }
            ];

            await update(taskRef, {
              invitedMembers: updatedInvitedMembers
            });
            // setShowNotification(true);
          } else {
            console.error("User is already invited.");
          }
        } else {
          console.error("Task does not exist.");
        }
      } catch (error) {
        console.error("Error inviting user: ", error);
      }
    } else {
      console.error("Selected user does not have a uid.");
    }
  };
  const handleChangeRole = async (userId: string, newRole: RoleToInvite) => {
    if (newRole === RoleToInvite.DELETE) {
      await handleRemoveUser(userId);
      return;
    }
    const taskRef = ref(db, `tasks/${task?.id}`);
    try {
      const taskSnapshot = await get(taskRef);
      if (taskSnapshot.exists()) {
        const taskData = taskSnapshot.val();
        const currentInvitedMembers: InvitedMember[] = taskData.invitedMembers || [];

        const updatedInvitedMembers = currentInvitedMembers.map(member =>
          member.id === userId ? { ...member, role: newRole } : member
        );

        await update(taskRef, {
          invitedMembers: updatedInvitedMembers
        });
        // setShowNotification(true);
      } else {
        console.error("Task does not exist.");
      }
    } catch (error) {
      console.error("Error changing user role: ", error);
    }
  };
  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRoleToInvite(e.target.value as RoleToInvite);
  };
  const handleChangeTaskStatus = async (newStatus: TaskStatus) => {
    const taskRef = ref(db, `tasks/${task?.id}`);
    try {
      const taskSnapshot = await get(taskRef);
      if (taskSnapshot.exists()) {
        await update(taskRef, {
          status: newStatus
        });
        // setShowNotification(true); // Если нужно уведомление об успешном изменении
      } else {
        console.error("Task does not exist.");
      }
    } catch (error) {
      console.error("Error changing task status: ", error);
    }
  };
  const handleRemoveUser = async (userId: string) => {
    const taskRef = ref(db, `tasks/${task?.id}`);
    try {
      const taskSnapshot = await get(taskRef);
      if (taskSnapshot.exists()) {
        const taskData = taskSnapshot.val();
        const currentInvitedMembers: InvitedMember[] = taskData.invitedMembers || [];

        const updatedInvitedMembers = currentInvitedMembers.filter(member => member.id !== userId);

        await update(taskRef, {
          invitedMembers: updatedInvitedMembers
        });
        // setTaskUsers([]);
        // setShowBlock && setShowBlock(false);
        // setShowNotification(true);
      } else {
        console.error("Task does not exist.");
      }
    } catch (error) {
      console.error("Error removing user: ", error);
    }
  };
  return (
    <DialogContentProduct>
      <CrossIconWrapper>
        <CircleIcon onClick={() => setShowBlock && setShowBlock(false)} />
      </CrossIconWrapper>
      <TitleText>
        Manage who can view this project
      </TitleText>
      <SmallTitleText>
        Select which users can access and view this project. Only users with access can view and
        edit the project
      </SmallTitleText>
      <DoubleBlock>
        <Input
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Input name"
        />
        {inputValue && owner ?
          <SelectUserBlock value={roleToInvite} onChange={handleRoleChange} className="status-select">
            <StatusButton value={RoleToInvite.MODERATOR}>Moderator</StatusButton>
            <StatusButton value={RoleToInvite.USER}>User</StatusButton>
            <StatusButton value={RoleToInvite.ASSISTANT}>Assistant</StatusButton>
          </SelectUserBlock> : null}

      </DoubleBlock>

      <DoubleBlock>
        <DropDownBlock>
          {suggestions.length > 0 && (
            <>
              {suggestions.map((user: OnlineUsers) => (
                <UserToInviteBlock key={user.userId}
                                   onClick={() => handleSuggestionClick(user)}>
                  <UserAvatar src={user.userData?.photoURL || DefaultAvatar} />
                  <UserName>{user.userData.name}</UserName>
                </UserToInviteBlock>
              ))}
            </>
          )}
          {owner && taskUsers.map((user: OnlineUsers) => (
            <UserBlock key={user.userId}>
              <UserAvatar src={user.userData?.photoURL || DefaultAvatar} />
              <UserName>{user.userData.name}</UserName>
              <SelectUserBlock
                //todo value={user.role}
                onChange={(e) => handleChangeRole(user.userId, e.target.value as RoleToInvite)}>
                <StatusButton value={RoleToInvite.USER}>User</StatusButton>
                <StatusButton value={RoleToInvite.MODERATOR}>Moderator</StatusButton>
                <StatusButton value={RoleToInvite.ASSISTANT}>Assistant</StatusButton>
                <StatusButton style={{ color: "red" }} value={RoleToInvite.DELETE}>Delete</StatusButton>
              </SelectUserBlock>
              {/*<DeleteButton style={{color:"white"}} onClick={() => handleRemoveUser(user.userId)}>X</DeleteButton>*/}
            </UserBlock>

          ))}
        </DropDownBlock>
      </DoubleBlock>
      {selectedUser ? <SendButton onClick={handleSendClick}>Invite</SendButton> : null}
      {/*<SmallTitleText>*/}
      {/*  Requests*/}
      {/*</SmallTitleText>*/}
      {/*<BottomBlock>*/}
      {/*  {suggestions.length > 0 && (*/}
      {/*    <>*/}
      {/*      {suggestions.map((user: OnlineUsers) => (*/}
      {/*        <UserBlock key={user.userId}*/}
      {/*                   onClick={() => handleSuggestionClick(user)}>*/}
      {/*          <UserAvatar src={user.userData?.photoURL || DefaultAvatar} />*/}
      {/*          <UserName>{user.userData.name}</UserName>*/}
      {/*          <SelectUserBlock value={roleToInvite} onClick={(e) =>*/}
      {/*            e.stopPropagation()}*/}
      {/*                           onChange={handleRoleChange} className="status-select">*/}
      {/*            <StatusButton value={RoleToInvite.MODERATOR}>Accept</StatusButton>*/}
      {/*            <StatusButton value={RoleToInvite.USER}>Decline</StatusButton>*/}
      {/*          </SelectUserBlock>*/}
      {/*        </UserBlock>*/}
      {/*      ))}*/}
      {/*    </>*/}
      {/*  )}*/}
      {/*</BottomBlock>*/}
      {owner ?
        <>
          <SmallTitleText>
            Change task status
          </SmallTitleText>

          <SelectUserBlock value={task?.status} onChange={(e) => handleChangeTaskStatus(e.target.value as TaskStatus)}
                           className="status-select">
            <StatusButton value={TaskStatus.OPEN}>Open</StatusButton>
            <StatusButton value={TaskStatus.ARCHIVE}>Archive</StatusButton>
            <StatusButton value={TaskStatus.PRIVATE}>Private</StatusButton>
          </SelectUserBlock>
        </> : null}

      <OpenRequestsButton>Requests</OpenRequestsButton>

    </DialogContentProduct>
  );
};
export default DialogContentSettingsTask;
