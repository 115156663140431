import React from "react";

const ArrowDownIcon: React.FC = () => {
    return (
        <svg width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <path id="icon" d="M4 6.66663L7.52856 10.1952C7.78906 10.4556 8.21094 10.4556 8.47144 10.1952L12 6.66663" stroke="#686B6E" strokeOpacity="1.000000" strokeWidth="1.500000" strokeLinejoin="round" strokeLinecap="round"/>
        </svg>
);
};

export default ArrowDownIcon