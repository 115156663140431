import styled from "styled-components";



export const Text = styled.p`
  position: fixed;
  top: 50%;
  left: 50%;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  color: aliceblue;
  transform: translate(-50%, -50%);
`;